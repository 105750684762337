import React, { useContext, useEffect } from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Avatar, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";

import { NavLink } from "react-router-dom";
import { AuthContext } from "../utils/AuthContext";

import { useTheme } from "next-themes";
import { Switch } from "@nextui-org/react";
import { Sun, Moon } from "@phosphor-icons/react";

function Header() {
  const { logout, userId } = useContext(AuthContext);
  const { theme, setTheme } = useTheme();

  const handleLogout = () => {
    logout();
  };

  return (
    <Navbar
      position="static"
      maxWidth="full"
      classNames={{
        base: "border-b border-default-100",
        item: [
          "flex",
          "relative",
          "h-full",
          "items-center",
          "data-[active=true]:after:content-['']",
          "data-[active=true]:after:absolute",
          "data-[active=true]:after:bottom-0",
          "data-[active=true]:after:left-0",
          "data-[active=true]:after:right-0",
          "data-[active=true]:after:h-[2px]",
          "data-[active=true]:after:rounded-[2px]",
          "data-[active=true]:after:bg-primary",
        ],
      }}
    >
      <NavbarBrand>{theme !== "dark" ? <img src={`${process.env.PUBLIC_URL}/litchi_svg_day.svg`} alt="" className="h-5" /> : <img src={`${process.env.PUBLIC_URL}/litchi_svg_night.svg`} alt="" className="h-5" />}</NavbarBrand>
      <NavbarContent className="hidden gap-4 sm:flex" justify="center">
        <NavLink to="/vrp" className={({ isActive }) => ` ${isActive ? "text-foreground" : "text-default-500"}`}>
          배차 관리
        </NavLink>
        <NavLink to="/optimalDispatch" className={({ isActive }) => ` ${isActive ? "text-foreground" : "text-default-500"}`}>
          최적 배차
        </NavLink>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem id="navbarPortal"></NavbarItem>
        <NavbarItem>
          <Button variant={"flat"} className="text-xl text-default-500" radius="full" size="sm" isIconOnly onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
            {theme === "dark" ? <Sun weight="fill" /> : <Moon weight="fill" />}
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Dropdown>
            <DropdownTrigger>
              <Avatar className="cursor-pointer" color="primary" size="sm"></Avatar>
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem key="logout" color="danger" onClick={handleLogout}>
                로그아웃
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}

export default Header;
