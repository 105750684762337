import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

import { CaretRight, CircleNotch, UserPlus } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/AuthContext";
import LoginImage from "../assets/images/login_bg.jpg";
import { Button, Input } from "@nextui-org/react";

function Login() {
  const [id, setId] = useState("");
  const [password, setPassword] = useState();
  const [error, setError] = useState(null);
  const [isLogging, setisLogging] = useState(false);
  const { login } = useContext(AuthContext);

  const idInput = useRef(null);
  const passwordInput = useRef(null);
  const submitBtn = useRef(null);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLogging) return;
    setisLogging(true);
    if (!id) {
      setError("아이디를 입력해주세요.");
      idInput.current.focus();
      setisLogging(false);
      return;
    }
    if (!password) {
      setError("비밀번호를 입력해주세요.");
      passwordInput.current.focus();
      setisLogging(false);
      return;
    }

    setError(null);

    try {
      await axios
        .post("/api/v1/jwt/token", {
          loginId: id,
          loginPw: password,
        })
        .then((response) => {
          if (response.data.code === "SUCC") {
            localStorage.setItem("token", response.data.data.token); // JWT 저장
            localStorage.setItem(
              "refreshToken",
              response.data.data.refreshToken
            ); // JWT 저장
            localStorage.setItem("userId", id); // JWT 저장
            login(id); // Context 로그인 상태 변경
            navigate("/optimalDispatch"); // 로그인 성공 시 /route으로 리다이렉트
          } else if (response.data.code === "FAIL") {
            setError(response.data.msg);
            setPassword("");
            passwordInput.current.focus();
          }
        });
    } catch (error) {
      // 에러 처리
      if (error.response.data.msg) {
        setError(error.response.data.msg);
        setPassword("");
        passwordInput.current.focus();
      }
    }
    setisLogging(false);
  };

  useEffect(() => {
    idInput.current.focus();
  }, []);

  return (
    <div className="flex h-screen min-h-[800px]">
      <div className="items-end justify-end flex-1 hidden text-foreborder-foreground lg:flex bg-default-50">
        <div className="flex flex-col w-4/5 h-3/4">
          <h1 className="mb-8 text-4xl font-bold">
            <span>나에게 꼭 맞는 운송</span>
            <span className="text-blue-500">.</span>
          </h1>
          <p className="mb-16 text-default-400">
            고객이 원했던 운송의 모든 것.
            <br />
            경로 최적화부터 운영까지
            <br />
            VUS에서 만나보세요.
          </p>
          <img
            className="flex-1 object-cover overflow-hidden rounded-tl-xl"
            src={LoginImage}
            alt=""
          />
        </div>
      </div>
      <div className="flex items-center justify-center w-full p-12 bg-background lg:w-5/12">
        <form className="flex flex-col w-96" onSubmit={handleSubmit}>
          <h1 className="mb-12 text-4xl font-bold text-default-900">로그인</h1>
          <Input
            label="아이디"
            className="mb-2"
            size="md"
            ref={idInput}
            type="id"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <Input
            label="비밀번호"
            className="mb-2"
            size="md"
            ref={passwordInput}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="mb-4 text-red-500">{error ? error : "\u00A0"}</p>
          <Button
            className="mb-4"
            size="lg"
            isLoading={isLogging}
            ref={submitBtn}
            type="submit"
          >
            로그인
          </Button>

          <a
            href="https://forms.gle/md4vCxH5AWyPFVV36"
            target="blank"
            className="flex items-center self-center w-4/5 mb-6 transition-all cursor-pointer h-14 hover:w-full"
          >
            <div className="flex items-center justify-center w-12 h-12 bg-indigo-400 rounded-full">
              <UserPlus size={28} className="text-white" />
            </div>
            <div className="flex flex-col justify-center ml-4">
              <span className="mb-1 text-xs text-default-400">
                아직 계정이 없으신가요?
              </span>
              <span className="text-lg font-medium leading-6 text-default-800">
                계정 신청하기
              </span>
            </div>
            <CaretRight size={24} className="ml-auto text-foreground" />
          </a>

          <p className="text-xs text-center text-default-300">
            Copyright © 2024 VUS All rights reserved.
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
