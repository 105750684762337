import React, { useState, useEffect } from "react";
import { Circle, Package, ThermometerSimple, Alarm, MapPin, HourglassMedium, Note } from "@phosphor-icons/react";
import { convertDistance, convertDuration, convertTimeToHHmm, getQtyUnitName, getVehicleTypeName, stringToPrettyColor } from "../../utils/FormatUtils";
import { motion, AnimatePresence } from "framer-motion";
import { CardBody, Card, ScrollShadow } from "@nextui-org/react";

function RouteContent({ data, selectedId, handleFlyToCoordinates }) {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    if (data && selectedId) {
      const selected = data.find((d) => d.id === selectedId);
      setSelectedDriver(selected);
    }
  }, [data, selectedId]);

  return (
    <AnimatePresence>
      {selectedId && selectedDriver && (
        <motion.div
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -20, opacity: 0 }}
          transition={{ duration: 0.1, ease: "easeOut" }}
          className="route-panel absolute w-[442px] left-0 top-0 h-full z-10 flex flex-col bg-default-50 border-r border-default-200"
        >
          <div className="flex flex-col p-4 pb-2 border-default-200">
            <div className="flex items-center h-8">
              <Circle weight="fill" color={stringToPrettyColor("hex", selectedDriver.name)} className="mr-1"></Circle>
              <div className="font-semibold text-default-800">{selectedDriver.name}</div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-3 p-4 text-sm border-b border-default-200 text-default-600">
            <div className="flex flex-col items-center">
              <div>{selectedDriver.nodeCnt}곳</div>
              <div className="text-xs text-default-400">경유지</div>
            </div>
            <div className="h-6 border-l border-default-200"></div>
            <div className="flex flex-col items-center">
              <div>{convertDistance(selectedDriver.totalDistance)}</div>
              <div className="text-xs text-default-400">이동거리</div>
            </div>
            <div className="h-6 border-l border-default-200"></div>
            <div className="flex flex-col items-center">
              <div>{convertDuration(selectedDriver.totalDuration)}</div>
              <div className="text-xs text-default-400">이동시간</div>
            </div>
            <div className="h-6 border-l border-default-200"></div>
            <div className="flex flex-col items-center">
              <div>
                {selectedDriver.totalItemQty}
                {getQtyUnitName(selectedDriver.itemQtyUnit)}
              </div>
              <div className="text-xs text-default-400">총 적재량</div>
            </div>
            <div className="h-6 border-l border-default-200"></div>
            <div className="flex flex-col items-center">
              <div>{selectedDriver.totalJobDuration + "분"}</div>
              <div className="text-xs text-default-400">총 작업시간</div>
            </div>
            <div className="h-6 border-l border-default-200"></div>
            <div className="flex flex-col items-center">
              <div>{selectedDriver.avgJobDuration + "분"}</div>
              <div className="text-xs text-default-400">평균 작업시간</div>
            </div>
            <div className="h-6 border-l border-default-200"></div>
            <div className="flex flex-col items-center">
              <div>{getVehicleTypeName(selectedDriver.vehicleType)}</div>
              <div className="text-xs text-default-400">차종</div>
            </div>
          </div>
          <ScrollShadow className="flex-1 p-4 space-y-3">
            {selectedDriver?.routeVisitList.map((visit, index) => (
              <div key={index} className="flex w-full cursor-pointer gap-x-2">
                <div className="flex flex-col flex-none py-1">
                  <div className="flex items-center w-20">
                    <MapPin weight="fill" color={stringToPrettyColor("hex", selectedDriver.name)}></MapPin>
                    <i className="mr-2 text-blue-400 ph-fill ph-circle"></i>
                    <span>{convertTimeToHHmm(visit.arrivalTime)}</span>
                  </div>
                  <div className="flex-1 border-l border-default-200 ml-1.5"></div>
                </div>
                <Card
                  radius="none"
                  className={"shadow-sm border-l-4 bg-background"}
                  style={{
                    borderColor: selectedPlace === index ? stringToPrettyColor("hex", selectedDriver.name) : "",
                  }}
                  shadow="none"
                  fullWidth={true}
                  isPressable={true}
                  onPress={() => {
                    setSelectedPlace(index);
                    console.log(selectedPlace, index);
                    handleFlyToCoordinates(visit.job.node.x, visit.job.node.y, { ...visit.job, ...visit.job.node });
                  }}
                >
                  <CardBody>
                    <div className="flex items-start mb-2">
                      <div className="flex items-center justify-center w-5 h-5 p-1 mr-1 text-sm border rounded-md border-default-200 text-default-400 bg-default-100">{index + 1}</div>
                      <span className="">{visit.job.node.address}</span>
                    </div>
                    <div className="flex flex-wrap w-full text-xs text-default-400 gap-x-10 gap-y-1">
                      <div className="flex items-center ">
                        <Alarm className="mr-1" />
                        <span>
                          {convertTimeToHHmm(visit.arrivalTime)}~{convertTimeToHHmm(visit.departureTime)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <Package className="mr-1" />
                        <span>
                          {visit.job.itemQty}
                          {getQtyUnitName(visit.job.itemQtyUnit)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <HourglassMedium className="mr-1" />
                        <span>{visit.job.jobDuration}분 작업</span>
                      </div>
                      <div className="flex items-center">
                        {visit.job.memo ? <Note className="mr-1" /> : ""}
                        <span>{visit.job.memo}</span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </ScrollShadow>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default RouteContent;
