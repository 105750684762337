import React, { useState, useEffect } from "react";
import {
  Circle,
  CaretRight,
  MapPin,
  RoadHorizon,
  Clock,
  Truck,
} from "@phosphor-icons/react";
import {
  convertDistance,
  convertDuration,
  getVehicleTypeName,
  stringToPrettyColor,
} from "../../utils/FormatUtils";
import Card from "../Card";
import { Button } from "@nextui-org/react";

function DriverCardListContent({
  data,
  failData,
  onSelectedIdChange,
  onSelectedFailArea,
}) {
  const [selectedId, setSelectedId] = useState(null);
  const handleSelect = (id) => {
    const newSelectedId = id === selectedId ? null : id;
    setSelectedId(newSelectedId);
  };

  useEffect(() => {
    onSelectedIdChange(selectedId);
  }, [selectedId, onSelectedIdChange]);

  return (
    <div className="flex flex-col space-y-3">
      {data?.map((driver) => (
        <DriverCard
          key={driver.id}
          driver={driver}
          isSelected={driver.id === selectedId}
          onSelect={handleSelect}
        />
      ))}
      {failData && (
        <FailDriverCard
          driver={failData}
          onSelectedFailArea={onSelectedFailArea}
          setSelectedId={setSelectedId}
        />
      )}
    </div>
  );
}

function DriverCard({ driver, isSelected, onSelect }) {
  return (
    <Button
      isSelected={isSelected}
      onClick={() => onSelect(driver.id)}
      hoverEffect={true}
      radius="none"
      className="flex h-auto p-3 font-normal border-l-4 shadow-sm justify-normal bg-background border-inherit text-medium"
      style={{
        borderLeft: `0.3rem solid ${stringToPrettyColor("hex", driver.name)} `,
      }}
    >
      <div className="flex gap-x-2">
        <span>{driver.name}</span>
        <span className="text-default-400">
          {driver.vehicleType === "999"
            ? ""
            : `(${getVehicleTypeName(driver.vehicleType)})`}
        </span>
      </div>
      <CaretRight className="ml-auto" />
    </Button>
  );
}

function FailDriverCard({ driver, onSelectedFailArea, setSelectedId }) {
  return (
    <Button
      onClick={() => {
        onSelectedFailArea();
        setSelectedId(false);
      }}
      hoverEffect={true}
      radius="none"
      className="flex h-auto p-3 font-normal border-l-4 shadow-sm justify-normal bg-background border-inherit text-medium"
      style={{
        borderLeft: `0.3rem solid ${stringToPrettyColor("hex", driver.name)} `,
      }}
    >
      <div className="flex gap-x-2">
        <span>{driver.name}</span>
        <span className="text-default-400">{`(${driver.routeVisitList.length} 곳) `}</span>
      </div>
      <CaretRight className="ml-auto" />
    </Button>
  );
}

export default DriverCardListContent;
