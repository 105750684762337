import React, { useEffect, useRef, useState } from "react";
import { DataTable, Loading } from "../components";
import {
  Tooltip,
  Button,
  Divider,
  ScrollShadow,
  Card,
  CardBody,
  Image,
  CardFooter,
  Tabs,
  Tab,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
} from "@nextui-org/react";
import { createPortal } from "react-dom";
import { FileInput } from "../components/FileInput";
import { DriverFileInput } from "../components/DriverFileInput";
import { AnimatePresence, motion } from "framer-motion";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/modal";
import {
  ListBullets,
  Plus,
  Table,
  Trash,
  X,
  FileXls,
  Question,
  CheckSquareOffset,
  Circuitry,
  CaretRight,
  QuestionMark,
  Paperclip,
  MapPinLine,
  WarningOctagon,
  Truck,
  MapPin,
  User,
  Flag,
  ArrowCounterClockwise,
} from "@phosphor-icons/react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/PrivateApi";
import HelpModal from "../components/OptimalDispatch/HelpModal";

import OptionsContent from "../components/OptimalDispatch/OptionsContent";
import PlacesContent from "../components/OptimalDispatch/PlacesContent";
import RouteContent from "../components/OptimalDispatch/RouteContent";
import DriverCardListContent from "../components/OptimalDispatch/DriverCardListContent";
import ScenarioModal from "../components/OptimalDispatch/ScenarioModal";
import { OPTIONS } from "../constants/options";

import { VusMap } from "../components";
import * as XLSX from "xlsx";
import {
  convertTimeToHHmm,
  convertDate,
  getQtyUnitName,
  getVehicleTypeName,
} from "../utils/FormatUtils";
import { adjustCoordinates } from "../utils/CoordUtils";
import SampleDataContent from "../components/OptimalDispatch/SampleDataContent";

function OptimalDispatch() {
  const mapRef = useRef(null);
  const ref = useRef(null);
  const [mounted, setMounted] = useState(false);
  const queryCliet = useQueryClient();

  useEffect(() => {
    ref.current = document.querySelector("#navbarPortal");
    setMounted(true);
  }, []);

  const [selectedId, setSelectedId] = useState(null);
  const [resultdata, setResultData] = useState(null);
  const [failData, setFailData] = useState([]);
  const [sideRenderComponent, setSideRenderComponent] = React.useState(null);
  const [sideRenderProps, setSideRenderProps] = React.useState(null);

  const [isSideOpen, setIsSideOpen] = React.useState(false);
  const [routeOption, setRouteOption] = React.useState("NONE");
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const {
    isOpen: vehicleModalIsOpen,
    onOpen: vehicleModalOnOpen,
    onOpenChange: vehicleModalOnOpenChange,
    onClose: vehicleModalOnClose,
  } = useDisclosure();
  const {
    isOpen: isRouteExConfirmOpen,
    onOpen: onRouteExConfirmOpen,
    onOpenChange: onRouteExConfirmOpenChange,
  } = useDisclosure();
  const {
    isOpen: isVehicleExConfirmOpen,
    onOpen: onVehicleExConfirmOpen,
    onOpenChange: onVehicleExConfirmOpenChange,
  } = useDisclosure();
  const {
    isOpen: isSampleConfirmOpen,
    onOpen: onSampleConfirmOpen,
    onOpenChange: onSampleConfirmOpenChange,
  } = useDisclosure();
  const {
    isOpen: isHelpOpen,
    onOpen: onHelpOpen,
    onOpenChange: onHelpOpenChange,
  } = useDisclosure({
    defaultOpen: !localStorage.getItem("disableHelp") ? true : false,
  });

  const [isOptimize, setIsOptimize] = useState(false);

  function downloadFile(dir) {
    // 파일 URL 생성
    const fileUrl = `${process.env.PUBLIC_URL}/${dir}`;

    // `<a>` 태그를 동적으로 생성하고 클릭 이벤트를 트리거하여 파일 다운로드
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", dir); // 다운로드될 파일 이름 설정
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const route = useQuery({
    queryKey: ["route"],
    queryFn: () => axiosInstance.get("/api/v1/route"),
    select: React.useCallback(
      (data) => adjustCoordinates(data.data.data.routeList, 3),
      []
    ),
    refetchOnMount: true,
  });
  const resetRoute = useMutation({
    mutationKey: ["resetRoute"],
    mutationFn: () => axiosInstance.delete("/api/v1/route"),
    onSuccess: () => {
      queryCliet.invalidateQueries({ queryKey: ["route"] });
      onClose();
    },
    onError: (error, variables, context) => {
      alert(error.response.data.msg || error.message);
    },
  });
  const updateRoute = useMutation({
    mutationKey: ["updateRoute"],
    mutationFn: (jsonData) => axiosInstance.post("/api/v1/route", jsonData),
    onSuccess: () => {
      queryCliet.invalidateQueries({ queryKey: ["route"] });
      onClose();
    },
    onError: (error, variables, context) => {
      alert(error.response.data.msg || error.message);
    },
  });

  const driver = useQuery({
    queryKey: ["driver"],
    queryFn: () => axiosInstance.get("/api/v1/driver"),
    select: (data) => data.data.data.driverList,
    refetchOnMount: true,
  });
  const resetDriver = useMutation({
    mutationKey: ["resetDriver"],
    mutationFn: () => axiosInstance.delete("/api/v1/driver"),
    onSuccess: () => {
      queryCliet.invalidateQueries({ queryKey: ["driver"] });
      onClose();
    },
    onError: (error, variables, context) => {
      alert(error.response.data.msg || error.message);
    },
  });
  const updateDriver = useMutation({
    mutationKey: ["updateDriver"],
    mutationFn: (jsonData) => axiosInstance.post("/api/v1/driver", jsonData),
    onSuccess: () => {
      queryCliet.invalidateQueries({ queryKey: ["driver"] });
      vehicleModalOnClose();
    },
    onError: (error, variables, context) => {
      alert(error.response.data.msg || error.message);
    },
  });

  const handleSideRenderComponent = (component, props) => {
    setSideRenderComponent(() => component);
    setSideRenderProps(props);
    setIsSideOpen(true);
  };

  const optimize = useMutation({
    mutationKey: ["optimize"],
    mutationFn: ({ routeOption }) => {
      return axiosInstance
        .post("/api/v1/route/vrp", { routeOption })
        .then((res) => {
          if (res?.data?.data?.routeList.length === 0) {
            throw new Error("경로 최적화에 실패하였습니다.");
          }
          return res;
        });
    },
    onSuccess: (res) => {
      try {
        console.log(res);
        let optimizedData = res.data.data.routeList;
        optimizedData = optimizedData.map((route) => {
          if (!route.pathJson) {
            route.pathJson = JSON.stringify([
              [
                route.routeVisitList[0].job.node.x,
                route.routeVisitList[0].job.node.y,
              ],
            ]);
          }
          return route;
        });
        let failRoute = res.data.data?.failRoute;
        setResultData(optimizedData);
        setFailData(failRoute);
        setIsOptimize(true);
      } catch (e) {
        console.error(e);
      }
    },
    onError: (error, variables, context) => {
      alert(error.response.data.msg || error.message);
    },
  });
  const handleObjectToExcel = () => {
    objectToExcel(
      resultdata.map((item, i) => {
        item.vehicleType = getVehicleTypeName(item.vehicleType);
        item.itemQtyUnit = getQtyUnitName(item.itemQtyUnit);
        return item;
      }),
      `경로최적화 결과 ${new Date().toLocaleString("ko-KR")}.xlsx`
    );
  };

  const objectToExcel = (data, fileName) => {
    let list = data.map((route) => {
      return route.routeVisitList.map((visit, index) => {
        return {
          name: route.name,
          index: index + 1,
          address: visit.job.node.address,
          jobDate: visit.job.jobDate,
          y: visit.job.node.y,
          x: visit.job.node.x,
          arrivalTime: visit.arrivalTime,
          departureTime: visit.departureTime,
          jobDuration: visit.job.jobDuration,
          itemQty: visit.job.itemQty,
          itemQtyUnit: visit.job.itemQtyUnit,
          vehicleType: route.vehicleType,
          memo: visit.job.memo,
        };
      });
    });

    list = list.flat();
    const heading = [
      [
        "기사명",
        "방문순서",
        "방문지 주소",
        "운행일",
        "위도",
        "경도",
        "도착시간",
        "출발시간",
        "작업시간",
        "적재량",
        "단위",
        "차종",
        "메모",
      ],
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);

    worksheet["!cols"] = [
      { wch: 10 }, // 기사명 열 너비
      { wch: 8 }, // 방문순서 열 너비
      { wch: 40 }, // 방문지 주소 열 너비
      { wch: 10 }, // 운행일 열 너비
      { wch: 10 }, // 위도 열 너비
      { wch: 10 }, // 경도 열 너비
      { wch: 10 }, // 도착시간 열 너비
      { wch: 10 }, // 출발시간 열 너비
      { wch: 10 }, // 작업시간 열 너비
      { wch: 8 }, // 적재량 열 너비
      { wch: 5 }, // 단위 열 너비
      { wch: 8 }, // 차종 열 너비
      { wch: 20 }, // 메모 열 너비
    ];
    XLSX.utils.sheet_add_aoa(worksheet, heading);
    XLSX.utils.sheet_add_json(worksheet, list, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  const handleFlyToCoordinates = (x, y, tooltipInfo) => {
    mapRef.current?.flyToCoordinates(x, y);
    mapRef.current?.setTooltipInfo(tooltipInfo);
  };

  const onSelectedIdChange = (id) => {
    if (id === selectedId) {
      return;
    }
    setIsSideOpen(true);
    setSelectedId(id);
    if (id) {
      const selectedRoute = resultdata.find((route) => route.id === id);
      mapRef.current?.setTooltipInfo(null);
      if (selectedRoute.pathJson) {
        mapRef.current?.flyToCenter(JSON.parse(selectedRoute.pathJson));
      }
    }
  };

  const onSelectedFailArea = () => {
    mapRef.current?.setTooltipInfo(null);
    let routePath = [];
    failData.routeVisitList.forEach((item) => {
      routePath.push([item.job.node.x, item.job.node.y]);
    });

    if (routePath.length > 0) {
      mapRef.current?.flyToCenter(routePath);
    }
  };

  const reDrawMarker = (id) => {
    mapRef.current?.clearLayers();
    let data = {};
    data.routeList = route.data;
    data.id = id;
    mapRef.current?.addNewMarkerLayer(data);
  };

  useEffect(() => {
    setIsSideOpen(false);
    console.log(optimize.status);
    if (resultdata && optimize.data) {
      mapRef.current?.clearLayers();
      resultdata.map((route) => mapRef.current?.addRoutePathLayer(route));
      resultdata.map((route) => mapRef.current?.addRouteMarkerLayer(route));
      const pathList = resultdata
        .map((route) => {
          return JSON.parse(route.pathJson);
        })
        .flat();
      mapRef.current?.flyToCenter(pathList);
    } else if (route?.data?.length > 0) {
      console.log("flyToCenter");
      setResultData(null);
      mapRef.current?.clearLayers();
      let data = {};
      data.routeList = route.data;
      mapRef.current?.addNewMarkerLayer(data);
      mapRef.current?.flyToCenter(route.data.map((node) => [node.x, node.y]));
    } else {
      mapRef.current?.clearLayers();
    }

    if (failData?.routeVisitList?.length > 0) {
      mapRef.current?.addRouteMarkerLayer(failData);
    }
  }, [resultdata, route.data, optimize.data, failData]);

  /* 시나리오 모달 */
  const [modalVisible, setModalVisible] = useState({
    edit: false,
    save: false,
    view: false,
    listView: false,
    load: false,
  });

  const openModal = (modal) => {
    setModalVisible((prev) => ({ ...prev, [modal]: true }));
  };

  const closeModal = (modal) => {
    setModalVisible((prev) => ({ ...prev, [modal]: false }));
  };

  return (
    <>
      {mounted && ref.current
        ? createPortal(
            <div className="flex gap-4">
              <Button
                size="sm"
                color="primary"
                variant={"flat"}
                radius="full"
                onPress={() => {
                  openModal("listView");
                }}
              >
                시나리오 비교하기
              </Button>
              <Button
                variant={"flat"}
                className="text-xl text-default-500"
                radius="full"
                size="sm"
                isIconOnly
                onPress={() => {
                  onHelpOpen();
                }}
              >
                <QuestionMark weight="bold" />
              </Button>
            </div>,
            document.getElementById("navbarPortal")
          )
        : null}
      <AnimatePresence>
        {isLoading && (
          <Loading
            title={"데이터를 불러오는중입니다."}
            content={"잠시만 기다려주세요"}
          />
        )}
      </AnimatePresence>

      <ScenarioModal
        modalVisible={modalVisible}
        routeOption={routeOption}
        openModal={openModal}
        closeModal={closeModal}
      ></ScenarioModal>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="5xl"
        classNames={{
          header: "pb-2 pt-8",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center gap-4 align-middle flex-rows">
                방문지 등록
              </ModalHeader>
              <ModalBody>
                <Tabs
                  disabledKeys={["button", "sample"]}
                  variant="underlined"
                  classNames={{
                    tabList:
                      "gap-6 w-full relative rounded-none p-0 border-b border-default-200",
                    cursor: "w-full",
                    tab: "max-w-fit px-0 h-14 data-[disabled=true]:opacity-100 data-[disabled=true]:cursor-default",
                    panel: "py-6",
                  }}
                >
                  <Tab key="upload" title="파일 업로드">
                    <FileInput
                      onClose={onClose}
                      updateRoute={updateRoute}
                      onSampleConfirmOpen={onSampleConfirmOpen}
                    />
                  </Tab>
                  <Tab
                    key="button"
                    className="cursor-pointer"
                    title={
                      <Button
                        size="xs"
                        className="p-2 data-[hover=true]:bg-default-50 h-full group"
                        variant="light"
                        color="default"
                        ripple={false}
                        onPress={() => {
                          downloadFile("route_sample.xlsx");
                        }}
                        disableRipple
                      >
                        <div className="flex items-center space-x-2">
                          <Paperclip></Paperclip>
                          <div className="flex flex-col items-start">
                            <span className="text-default-700">
                              엑셀 양식 다운로드
                            </span>
                          </div>
                        </div>
                      </Button>
                    }
                  ></Tab>
                  <Tab
                    key="sample"
                    className="ml-auto cursor-pointer"
                    title={
                      <Button
                        size="xs"
                        className="p-2 data-[hover=true]:bg-default-50 h-full group  !transition-all ease-in-out"
                        variant="light"
                        color="default"
                        ripple={false}
                        onPress={() => {
                          onClose();
                          onSampleConfirmOpen();
                        }}
                        disableRipple
                      >
                        <div className="flex items-center space-x-2">
                          <div className="flex items-center justify-center w-10 rounded-full aspect-square bg-default-200">
                            <Circuitry className="text-2xl text-default-500" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="text-xs text-default-400">
                              방문이 처음이신가요?
                            </span>
                            <span className="text-default-700">
                              샘플 데이터로 체험해보세요
                            </span>
                          </div>
                        </div>
                        <CaretRight className="group-data-[hover=true]:ml-2 transition-all text-default-800"></CaretRight>
                      </Button>
                    }
                  ></Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                {/* <Button color="danger" variant="light" onPress={onClose}>
                  닫기
                </Button> */}
                <Button color="primary" onPress={onClose}>
                  닫기
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={vehicleModalIsOpen}
        onOpenChange={vehicleModalOnOpenChange}
        size="5xl"
        classNames={{
          header: "pb-2 pt-8",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center gap-4 align-middle flex-rows">
                차량/기사 등록
              </ModalHeader>
              <ModalBody>
                <Tabs
                  disabledKeys={["button", "sample"]}
                  variant="underlined"
                  classNames={{
                    tabList:
                      "gap-6 w-full relative rounded-none p-0 border-b border-default-200",
                    cursor: "w-full",
                    tab: "max-w-fit px-0 h-14 data-[disabled=true]:opacity-100 data-[disabled=true]:cursor-default",
                    panel: "py-6",
                  }}
                >
                  <Tab key="upload" title="파일 업로드">
                    <DriverFileInput updateDriver={updateDriver} />
                  </Tab>
                  <Tab
                    key="button"
                    className="cursor-pointer"
                    title={
                      <Button
                        size="xs"
                        className="p-2 data-[hover=true]:bg-default-50 h-full group"
                        variant="light"
                        color="default"
                        ripple={false}
                        onPress={() => {
                          downloadFile("vehicle_sample.xlsx");
                        }}
                        disableRipple
                      >
                        <div className="flex items-center space-x-2">
                          <Paperclip></Paperclip>
                          <div className="flex flex-col items-start">
                            <span className="text-default-700">
                              차량/기사 엑셀 양식 다운로드
                            </span>
                          </div>
                        </div>
                      </Button>
                    }
                  ></Tab>
                  <Tab
                    key="sample"
                    className="ml-auto cursor-pointer"
                    title={
                      <Button
                        size="xs"
                        className="p-2 data-[hover=true]:bg-default-50 h-full group  !transition-all ease-in-out"
                        variant="light"
                        color="default"
                        ripple={false}
                        onPress={() => {
                          onClose();
                          onSampleConfirmOpen();
                        }}
                        disableRipple
                      >
                        <div className="flex items-center space-x-2">
                          <div className="flex items-center justify-center w-10 rounded-full aspect-square bg-default-200">
                            <Circuitry className="text-2xl text-default-500" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="text-xs text-default-400">
                              방문이 처음이신가요?
                            </span>
                            <span className="text-default-700">
                              샘플 데이터로 체험해보세요
                            </span>
                          </div>
                        </div>
                        <CaretRight className="group-data-[hover=true]:ml-2 transition-all text-default-800"></CaretRight>
                      </Button>
                    }
                  ></Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                {/* <Button color="danger" variant="light" onPress={onClose}>
                  닫기
                </Button> */}
                <Button color="primary" onPress={onClose}>
                  닫기
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isRouteExConfirmOpen}
        onOpenChange={onRouteExConfirmOpenChange}
        scrollBehavior="inside"
        size="5xl"
        classNames={{
          header: "pb-2 pt-8",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                방문지 확인
              </ModalHeader>
              <ModalBody className="overflow-auto">
                <ScrollShadow
                  orientation="horizontal"
                  className="max-h-[32rem]"
                >
                  <DataTable
                    data={route.data.map((item) => ({
                      ...item,
                      jobStartTime: convertTimeToHHmm(item.jobStartTime),
                      jobEndTime: convertTimeToHHmm(item.jobEndTime),
                      jobDate: convertDate(item.jobDate, "-"),
                      itemQtyUnit: getQtyUnitName(item.itemQtyUnit),
                      requireVehicleType: getVehicleTypeName(
                        item.requireVehicleType
                      ),
                    }))}
                    sort={[
                      "index",
                      "address",
                      "jobDate",
                      "x",
                      "y",
                      "jobStartTime",
                      "jobEndTime",
                      "jobDuration",
                      "itemQty",
                      "itemQtyUnit",
                      "requireVehicleType",
                      "memo",
                    ]}
                    headers={{
                      index: "no",
                      address: "주소",
                      jobDate: "작업일",
                      x: "경도",
                      y: "위도",
                      jobStartTime: "시작시간",
                      jobEndTime: "종료시간",
                      jobDuration: "작업시간",
                      itemQty: "적재량",
                      itemQtyUnit: "단위",
                      requireVehicleType: "차종",
                      memo: "메모",
                    }}
                    indexMode={true}
                    className="table table-auto"
                  />
                </ScrollShadow>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onClose}>
                  닫기
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isVehicleExConfirmOpen}
        onOpenChange={onVehicleExConfirmOpenChange}
        scrollBehavior="inside"
        size="5xl"
        classNames={{
          header: "pb-2 pt-8",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                기사/차량 확인
              </ModalHeader>
              <ModalBody className="overflow-auto">
                <ScrollShadow
                  orientation="horizontal"
                  className="max-h-[32rem]"
                >
                  <DataTable
                    data={driver.data.map((item) => ({
                      ...item,
                      breakStartTime: convertTimeToHHmm(item.breakStartTime),
                      breakEndTime: convertTimeToHHmm(item.breakEndTime),
                      workStartTime: convertTimeToHHmm(item.workStartTime),
                      workEndTime: convertTimeToHHmm(item.workEndTime),
                      vehicleType: getVehicleTypeName(item.vehicleType),
                    }))}
                    sort={[
                      "address",
                      "name",
                      "vehicleNo",
                      "vehicleType",
                      "vehicleWeightCapacity",
                      "breakStartTime",
                      "breakEndTime",
                      "workStartTime",
                      "workEndTime",
                      "vehicleCostPerKm",
                      "x",
                      "y",
                    ]}
                    headers={{
                      index: "no",
                      address: "주소",
                      name: "이름",
                      vehicleNo: "차량번호",
                      vehicleType: "차종",
                      vehicleWeightCapacity: "작업량",
                      breakStartTime: "휴게시작시간",
                      breakEndTime: "휴게종료시간",
                      workStartTime: "근무시작시간",
                      workEndTime: "근무종료시간",
                      vehicleCostPerKm: "차량키로미터유류비",
                      x: "위도",
                      y: "경도",
                    }}
                    indexMode={true}
                    className="table table-auto"
                  />
                </ScrollShadow>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onClose}>
                  닫기
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSampleConfirmOpen}
        onOpenChange={onSampleConfirmOpenChange}
        size="5xl"
        classNames={{
          header: "pb-2 pt-8",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                샘플데이터
              </ModalHeader>
              <ModalBody className="overflow-x-auto">
                <SampleDataContent
                  onClose={onClose}
                  setIsSideOpen={setIsSideOpen}
                  setRouteOption={setRouteOption}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onClose}>
                  닫기
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <HelpModal
        isOpen={isHelpOpen}
        onOpenChange={onHelpOpenChange}
        onSampleConfirmOpen={onSampleConfirmOpen}
      />

      <main className="flex flex-1">
        {!resultdata && (
          <div className="w-[350px] bg-default-50 flex flex-col border-r border-default-200">
            {/* 방문지 등록 */}
            <div className="flex flex-col p-4 space-y-4 border-b shrink-0 bg-default-50 border-default-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-1">
                  <MapPinLine
                    weight="fill"
                    className="text-default-500"
                  ></MapPinLine>
                  <span className="font-semibold text-default-800">
                    방문지 등록
                  </span>
                  <span className="text-danger-500">*</span>
                </div>
                <Button
                  size="sm"
                  className="bg-default-200 text-default-600"
                  startContent={<Plus />}
                  onPress={onOpen}
                >
                  업로드
                </Button>
              </div>
              <div className="flex items-center justify-between">
                {route.data?.length > 0 ? (
                  <span> {route.data?.length}개 방문지</span>
                ) : (
                  <span className="text-default-400">데이터 없음</span>
                )}
                <Button
                  isDisabled={route.data?.length <= 0}
                  color="primary"
                  variant="flat"
                  onPress={() =>
                    handleSideRenderComponent(PlacesContent, {
                      data: route.data,
                      onRouteExConfirmOpen: onRouteExConfirmOpen,
                      handleFlyToCoordinates: handleFlyToCoordinates,
                      reDrawMarker: reDrawMarker,
                    })
                  }
                  startContent={<ListBullets className="text-xl" />}
                  size="sm"
                >
                  상세 리스트
                </Button>
              </div>
            </div>

            {/* 차량/기사 등록 */}
            <div className="flex flex-col p-4 space-y-4 border-b shrink-0 bg-default-50 border-default-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-1">
                  <User weight="fill" className="text-default-500"></User>
                  <span className="font-semibold text-default-800">
                    차량/기사 등록
                  </span>
                  <Tooltip
                    content={
                      <>
                        <div>차량/기사를 등록하지 않아도 시스템이 자동으로</div>
                        <div>배차할 차량을 계산하여 결과를 보여줍니다.</div>
                      </>
                    }
                    color="foreground"
                    placement="bottom"
                  >
                    <Question weight="fill" className="text-default-600" />
                  </Tooltip>
                </div>
                <Button
                  size="sm"
                  className="bg-default-200 text-default-600"
                  startContent={<Plus />}
                  onPress={vehicleModalOnOpen}
                >
                  업로드
                </Button>
              </div>
              <div className="flex items-center justify-between">
                {driver.data?.length > 0 ? (
                  <span className="text-default-800">
                    {driver.data?.length}개 차량/기사
                  </span>
                ) : (
                  <span className="text-default-400">데이터 없음</span>
                )}
                <Button
                  isDisabled={!driver.data?.length > 0}
                  color="primary"
                  variant="flat"
                  onPress={onVehicleExConfirmOpen}
                  startContent={<Table className="text-xl" />}
                  size="sm"
                >
                  상세 테이블
                </Button>
              </div>
            </div>

            {/* 상세옵션 */}
            <div className="flex flex-col p-4 space-y-4 border-b shrink-0 bg-default-50 border-default-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-1">
                  <MapPinLine
                    weight="fill"
                    className="text-default-500"
                  ></MapPinLine>
                  <span className="font-semibold text-default-800">
                    상세옵션
                  </span>
                </div>
                <Button
                  size="sm"
                  variant="flat"
                  color="primary"
                  onPress={() =>
                    handleSideRenderComponent(OptionsContent, {
                      defaultExpandedKeys: routeOption,
                      onAccordionChange: setRouteOption,
                    })
                  }
                  startContent={<CheckSquareOffset />}
                >
                  선택하기
                </Button>
              </div>
              <div className="flex items-center justify-between">
                {OPTIONS[routeOption]}
              </div>
            </div>

            <div className="flex gap-2 p-4 mt-auto bg-default-50 ">
              <Dropdown className="shadow-xl" placement="bottom">
                <DropdownTrigger>
                  <Button
                    isLoading={resetDriver.isLoading || resetRoute.isLoading}
                    isIconOnly
                    variant="flat"
                  >
                    <Trash />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  closeOnSelect
                  aria-label="Actions"
                  color="default"
                  variant="flat"
                >
                  <DropdownSection title="개별 작업">
                    <DropdownItem
                      key="route"
                      onPress={() => resetRoute.mutate()}
                      description="등록된 방문지를 초기화 합니다."
                      shortcut="⌘R"
                      startContent={<MapPinLine size={32} weight="duotone" />}
                    >
                      방문지 초기화
                    </DropdownItem>
                    <DropdownItem
                      key="driver"
                      onPress={() => resetDriver.mutate()}
                      description="등록된 차량/기사 정보를 초기화 합니다."
                      shortcut="⌘D"
                      startContent={<Truck size={32} weight="duotone" />}
                    >
                      차량/기사 초기화
                    </DropdownItem>
                  </DropdownSection>
                  <DropdownSection title="일괄 작업">
                    <DropdownItem
                      key="delete"
                      onPress={() => {
                        resetDriver.mutate();
                        resetRoute.mutate();
                      }}
                      className="text-danger"
                      color="danger"
                      description="방문지, 기사 차량 데이터를 초기화 합니다."
                      shortcut="⌘A"
                      startContent={
                        <WarningOctagon size={32} weight="duotone" />
                      }
                    >
                      전체 초기화
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
              <Button
                color="primary"
                isLoading={optimize.isPending}
                fullWidth
                isDisabled={route.data?.length === 0 || optimize.isPending}
                onPress={() => optimize.mutate({ routeOption })}
              >
                배차최적화
              </Button>
            </div>
          </div>
        )}

        {resultdata && (
          <div className="w-[350px] bg-default-50 flex flex-col border-r border-default-200">
            {/* 방문지 등록 */}
            <div className="flex flex-col p-4 space-y-4 border-b shrink-0 border-default-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-1">
                  <Flag weight="fill" className="text-default-500"></Flag>
                  <span className="font-semibold text-default-800">
                    최적배차 결과
                  </span>
                </div>
                <Button
                  variant="flat"
                  color="success"
                  onPress={() => {
                    handleObjectToExcel();
                  }}
                  startContent={<FileXls size={20} />}
                  size="sm"
                >
                  결과 다운로드
                </Button>
              </div>
            </div>
            <ScrollShadow hideScrollBar="true" className="relative flex-1 p-4">
              <DriverCardListContent
                data={resultdata}
                failData={failData}
                onSelectedIdChange={onSelectedIdChange}
                onSelectedFailArea={onSelectedFailArea}
              />
            </ScrollShadow>

            <div className="flex gap-2 p-4 mt-auto bg-default-50">
              <Button
                variant="flat"
                isIconOnly
                onPress={() => {
                  setIsSideOpen(false);
                  setResultData(null);
                }}
              >
                <ArrowCounterClockwise></ArrowCounterClockwise>
              </Button>
              <Button
                color="primary"
                fullWidth
                onPress={() => openModal("save")}
              >
                시나리오 저장
              </Button>
            </div>
          </div>
        )}
        <div className="relative flex flex-1 overflow-hidden bg-background">
          <VusMap ref={mapRef} />
          <AnimatePresence>
            {isSideOpen && !resultdata && (
              <motion.div
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -20, opacity: 0 }}
                transition={{ duration: 0.1, ease: "easeOut" }}
                className="relative w-[350px] bg-default-50 flex flex-col border-r border-default-200"
              >
                {!resultdata && (
                  <>
                    <Button
                      isIconOnly
                      onPress={() => setIsSideOpen(false)}
                      className="absolute flex items-center justify-center w-10 h-10 border-l-0 rounded-l-none border-1 border-default-200 bg-default-50 left-full top-12 hover:bg-default-100"
                    >
                      <X className="text-lg text-default-500" />
                    </Button>

                    {sideRenderComponent &&
                      React.createElement(sideRenderComponent, {
                        ...sideRenderProps,
                      })}
                  </>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          {resultdata && (
            <RouteContent
              data={resultdata}
              selectedId={selectedId}
              handleFlyToCoordinates={handleFlyToCoordinates}
            />
          )}
        </div>
      </main>
    </>
  );
}
export default OptimalDispatch;
