import React from "react";
const Card = ({ children, isSelected, hoverEffect, handleClick, addClass, addStyle }) => {
  const defaultStyle = "border border-default-200 bg-background rounded-md p-3 ";
  const selectedStyle = "border-1 border-blue-400 ";
  const hoverStyle = "hover:bg-default-50 ";

  const getstyle = () => {
    let style = "";

    if (isSelected) {
      style += selectedStyle;
    }
    if (hoverEffect) {
      style += hoverStyle;
    }
    if (handleClick || hoverEffect) {
      style += "cursor-pointer ";
    }
    if (addClass) {
      style += addClass;
    }

    return defaultStyle + style;
  };
  return (
    <div className={getstyle()} onClick={handleClick} style={addStyle}>
      {children}
    </div>
  );
};

export default Card;
