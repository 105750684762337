import React, { useState, useEffect } from "react";
import { Circle, CaretRight, MapPin, RoadHorizon, Clock, Truck } from "@phosphor-icons/react";
import { convertDistance, convertDuration, getVehicleTypeName, stringToPrettyColor } from "../utils/FormatUtils";
import Card from "./Card";

function DriverCardList({ data, onSelectedIdChange }) {
  const [selectedId, setSelectedId] = useState(null);
  const handleSelect = (id) => {
    const newSelectedId = id === selectedId ? null : id;
    setSelectedId(newSelectedId);
  };

  useEffect(() => {
    onSelectedIdChange(selectedId);
  }, [selectedId, onSelectedIdChange]);

  return (
    <div className="flex flex-col py-2 space-y-2 ">
      {data?.map((driver) => (
        <DriverCard key={driver.id} driver={driver} isSelected={driver.id === selectedId} onSelect={handleSelect} />
      ))}
    </div>
  );
}

function DriverCard({ driver, isSelected, onSelect }) {
  return (
    <Card isSelected={isSelected} handleClick={() => onSelect(driver.id)} hoverEffect={true}>
      <div className="flex items-center mb-2">
        <Circle weight="fill" className="mr-1" color={stringToPrettyColor("hex", driver.name)}></Circle>
        <span className="text-sm">{driver.name}</span>
        <CaretRight className="ml-auto" />
      </div>
      <div className="flex justify-between text-xs text-default-400">
        <div className="flex items-center gap-1">
          <Truck className="mr-1" />
          {getVehicleTypeName(driver.vehicleType)}
        </div>
        <div className="flex items-center gap-1">
          <MapPin size={14} />
          <span>{driver.nodeCnt}곳</span>
        </div>
        <div className="flex items-center gap-1">
          <Clock size={14} />
          <span>{convertDuration(driver.totalDuration)}</span>
        </div>
        <div className="flex items-center gap-1">
          <RoadHorizon size={14} />
          <span>{convertDistance(driver.totalDistance)}</span>
        </div>
      </div>
    </Card>
  );
}

export default DriverCardList;
