import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalContent, Button, Tabs, Tab, ScrollShadow, Checkbox } from "@nextui-org/react";
import { Circuitry, CaretRight, NumberCircleOne, NumberCircleTwo, NumberCircleThree, Plus, X, ListBullets, CheckSquareOffset, Trash, Circle, Download, Minus, Square } from "@phosphor-icons/react";
import { TRANSITION_EASINGS } from "@nextui-org/framer-transitions";
import VusMap from "../VusMap";
import img_src from "../../assets/images/rafiki.svg";
import { useCookies } from "react-cookie";

const HelpModal = ({ isOpen, onOpenChange, onSampleConfirmOpen }) => {
  const [isSelected, setIsSelected] = React.useState(!localStorage.getItem("disableHelp") ? true : false);
  const [tab, setTab] = useState("one");
  const tabList = ["one", "two", "three"];
  const handleNextTab = (currentTab) => {
    const currentIndex = tabList.indexOf(currentTab);
    const isLastTab = currentIndex === tabList.length - 1;

    if (!isLastTab) {
      const nextIndex = currentIndex + 1;
      setTab(tabList[nextIndex]);
    }
  };
  useEffect(() => {
    if (isSelected) {
      localStorage.setItem("disableHelp", "disable");
    } else {
      localStorage.removeItem("disableHelp");
    }
  }, [isSelected]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="5xl"
      classNames={{
        header: "pb-2 pt-8",
      }}
      motionProps={{
        variants: {
          enter: {
            scale: 1,
            y: "var(--slide-enter)",
            opacity: 1,
            transition: {
              scale: {
                duration: 0.4,
                ease: TRANSITION_EASINGS.ease,
              },
              opacity: {
                duration: 0.4,
                ease: TRANSITION_EASINGS.ease,
              },
              y: {
                type: "spring",
                bounce: 0,
                duration: 0.6,
              },
            },
          },
          exit: {
            scale: 1.03, // NextUI default 1.03
            y: "var(--slide-exit)",
            opacity: 0,
            transition: {
              duration: 0.3,
              ease: TRANSITION_EASINGS.ease,
            },
          },
        },
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">최적 배차 화면 안내</ModalHeader>
            <ModalBody>
              <Tabs
                disabledKeys={["button"]}
                variant="underlined"
                selectedKey={tab}
                onSelectionChange={setTab}
                classNames={{
                  tabList: "gap-6 w-full relative rounded-none p-0 border-b border-default-200",
                  cursor: "w-full",
                  tab: "max-w-fit px-0 h-14 data-[disabled=true]:opacity-100 data-[disabled=true]:cursor-default",
                  panel: "py-6",
                }}
              >
                <Tab
                  key="one"
                  title={
                    <div className="flex items-center gap-1">
                      <NumberCircleOne size={18} weight="fill" />
                      <span>배차 옵션</span>
                    </div>
                  }
                >
                  <div className="flex px-4 space-x-8">
                    <div className="flex flex-col w-80">
                      <span className="mb-2 font-bold text-primary-400">🚙 배차 옵션 설정</span>
                      <div className="relative p-3 mb-2 text-sm text-white rounded-lg bg-primary-400">
                        <p>최적 배차는 방문지, 차량/기사, 상세 옵션 정보를 입력 후 진행할 수 있습니다.</p>
                        <CaretRight weight="fill" className="absolute right-0 text-2xl translate-x-3 top-1/3 text-primary-400"></CaretRight>
                      </div>
                      <div className="relative p-3 mb-8 text-sm text-white rounded-lg bg-primary-400">
                        <p>
                          <span className="text-yellow-400">방문지 정보 입력은 필수</span>
                          이며, 차량/기사 및 상세 옵션은 별도의 정보 입력 없이도 최적 배차가 진행됩니다.
                        </p>
                        <CaretRight weight="fill" className="absolute right-0 text-2xl translate-x-3 top-1/3 text-primary-400"></CaretRight>
                      </div>
                      <span className="mt-auto mb-2 text-sm text-default-500">💡 차량/기사 데이터가 없다면 아래와 같은 기사의 정보로 자동 배차됩니다.</span>
                      <ul className="list-disc text-sm bg-default-100 p-2 [&>li]:ml-4 text-default-500 rounded-lg">
                        <li>차량 : 1톤/카고</li>
                        <li>근무시간 : 09시 - 18시 근무 ,</li>
                        <li>휴게시간 : 12시 - 13시 휴게시간</li>
                        <li>기사 1명당 평균 방문지 수 외 5건</li>
                      </ul>
                    </div>
                    <div className="flex flex-col flex-1 overflow-hidden border rounded-lg bg-background h-96 border-default-300">
                      <div className="relative flex px-2 py-1 text-xs border-b border-default-300 bg-default-200">
                        {/Win/.test(navigator.userAgent) ? (
                          <div className="flex items-center ml-auto space-x-1">
                            <Minus weight="bold"></Minus>
                            <Square weight="bold"></Square>
                            <X weight="bold"></X>
                          </div>
                        ) : (
                          <div className="flex items-center space-x-1">
                            <Circle weight="fill" className="text-danger-500"></Circle>
                            <Circle weight="fill" className="text-warning-500"></Circle>
                            <Circle weight="fill" className="text-success-500"></Circle>
                          </div>
                        )}
                        {/* <span className="px-4 mx-auto rounded-md bg-default-100 text-default-300">https://demo.withvus.ai</span> */}
                      </div>
                      <div className="p-4 py-2 border-b bg-background border-default-200">
                        <img src={`${process.env.PUBLIC_URL}/litchi_svg_day.svg`} alt="" className="block h-4 dark:hidden" />
                        <img src={`${process.env.PUBLIC_URL}/litchi_svg_night.svg`} alt="" className="hidden h-4 dark:block" />
                      </div>
                      <div className="flex flex-1">
                        <div className="flex flex-col w-48 border-2 bg-default-50 border-primary-400 rounded-es-lg">
                          <div className="flex flex-col px-4 py-3 space-y-2 border-b border-default-200">
                            <div className="flex items-center justify-between text-xs">
                              <span>
                                방문지 등록
                                <b className="text-danger-400">*</b>
                              </span>
                              <Button size="sm" className="h-6 px-2 text-xs text-default-500 bg-default-200" startContent={<Plus size={12}></Plus>}>
                                업로드
                              </Button>
                            </div>
                            <div className="flex items-center justify-between text-xs">
                              <span>10개 방문지</span>
                              <Button size="sm" className="h-6 px-2 text-xs" color="primary" variant="flat" startContent={<ListBullets size={12}></ListBullets>}>
                                상세보기
                              </Button>
                            </div>
                          </div>
                          <div className="flex flex-col px-4 py-3 space-y-2 border-b border-default-200">
                            <div className="flex items-center justify-between text-xs">
                              <span>차량/기사 등록</span>
                              <Button size="sm" className="h-6 px-2 text-xs text-default-500 bg-default-200" startContent={<Plus size={12}></Plus>}>
                                업로드
                              </Button>
                            </div>
                            <div className="flex items-center justify-between text-xs">
                              <span className="text-default-500">데이터 없음</span>
                              <Button isDisabled size="sm" className="h-6 px-2 text-xs" color="primary" variant="flat" startContent={<ListBullets size={12}></ListBullets>}>
                                상세보기
                              </Button>
                            </div>
                          </div>
                          <div className="flex flex-col px-4 py-3 space-y-2 border-b border-default-200">
                            <div className="flex items-center justify-between text-xs">
                              <span>상세옵션</span>
                              <Button size="sm" className="h-6 px-2 text-xs text-default-500 bg-default-200" startContent={<CheckSquareOffset size={12}></CheckSquareOffset>}>
                                선택하기
                              </Button>
                            </div>
                            <div className="flex items-center justify-between text-xs">
                              <span>기본</span>
                            </div>
                          </div>
                          <div className="flex p-4 mt-auto space-x-2">
                            <Button fullWidth size="sm" isIconOnly className="p-0 text-xs " color="default">
                              <Trash></Trash>
                            </Button>
                            <Button fullWidth size="sm" className="px-2 text-xs " color="primary">
                              배차 최적화
                            </Button>
                          </div>
                        </div>
                        <div className="relative flex-1">
                          <VusMap />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  key="two"
                  title={
                    <div className="flex items-center gap-1">
                      <NumberCircleTwo size={18} weight="fill" />
                      <span>파일 업로드</span>
                    </div>
                  }
                >
                  <div className="flex px-4 space-x-8">
                    <div className="flex flex-col w-80">
                      <span className="mb-2 font-bold text-primary-400">📂 파일 업로드</span>
                      <div className="relative p-3 mb-2 text-sm text-white rounded-lg bg-primary-400">
                        <p>엑셀 양식을 다운로드한 후, 양식을 올바르게 작성하여 업로드하면 방문지, 차량/기사 데이터를 간편하게 등록할 수 있습니다.</p>
                        <CaretRight weight="fill" className="absolute right-0 text-2xl translate-x-3 top-1/3 text-primary-400"></CaretRight>
                      </div>
                      <div className="relative p-3 mb-8 text-sm text-white rounded-lg bg-primary-400">
                        <p>
                          입력 과정이 어려우시면
                          <span className="text-yellow-400"> 샘플 데이터</span>를 사용해보세요.
                        </p>
                        <CaretRight weight="fill" className="absolute right-0 text-2xl translate-x-3 top-1/3 text-primary-400"></CaretRight>
                      </div>
                      <span className="mt-auto mb-2 text-sm text-default-500">
                        🙅 아래와 같은 경우에는 파일 업로드가 <span className="text-danger">불가</span>합니다.
                      </span>
                      <ol className="list-decimal text-sm bg-default-100 p-2 [&>li]:ml-4 text-default-500 rounded-lg">
                        <li>업로드 양식이 다른 경우</li>
                        <li>입력된 정보가 없는 경우</li>
                        <li>엑셀 파일이 아닌 다른 형식의 파일을 업로드 한 경우</li>
                      </ol>
                    </div>
                    <div className="flex flex-col flex-1 overflow-hidden border rounded-lg bg-background h-96 border-default-300">
                      <div className="flex items-start justify-between p-4 text-lg font-bold">
                        <span>파일업로드</span>
                        <X className="text-sm"></X>
                      </div>
                      <div className="flex gap-4 px-4 border-b border-default-200">
                        <div className="flex items-center h-10 text-xs border-b">파일 업로드</div>
                        <div className="flex items-center h-10 px-2 py-1 space-x-1 text-xs border-2 rounded-lg border-primary-400">
                          <div className="p-1 text-sm rounded-full bg-default-50">
                            <Download />
                          </div>
                          <div className="flex flex-col">
                            <span>엑셀 양식 다운로드</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center flex-1 pt-8 space-y-2">
                        <img src={img_src} className="h-28" alt="" />
                        <Button size="sm" color="primary" className="text-xs">
                          찾아보기
                        </Button>
                        <span className="text-sm">또는 파일을 여기로 드래그 하세요.</span>
                      </div>
                      <div className="flex justify-end p-4">
                        <Button size="sm" className="text-xs text-default-500 bg-default-200">
                          닫기
                        </Button>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  key="three"
                  title={
                    <div className="flex items-center gap-1">
                      <NumberCircleThree size={18} weight="fill" />
                      <span>시나리오 저장</span>
                    </div>
                  }
                >
                  <div className="flex px-4 space-x-8">
                    <div className="flex flex-col w-80">
                      <span className="mb-2 font-bold text-primary-400">📑 시나리오 저장</span>
                      <div className="relative p-3 mb-8 text-sm text-white rounded-lg bg-primary-400">
                        <p>
                          모든 최적 배차 결과는 '<span className="text-yellow-400">시나리오 저장</span>' 기능을 통해 언제든지 열람할 수 있습니다. 또한, 시나리오 간 비교 결과를 통해 어떤 운행 시나리오가 더 합리적인지 판단하는 데 도움이 됩니다.
                        </p>
                        <CaretRight weight="fill" className="absolute right-0 text-2xl translate-x-3 top-1/3 text-primary-400"></CaretRight>
                      </div>
                      <span className="mt-auto mb-2 text-sm text-default-500">👀 최대 5개의 시나리오를 선택하여 아래 내용의 비교 분석 내용을 확인할 수 있어요.</span>
                      <ol className="list-disc text-sm bg-default-100 p-2 [&>li]:ml-4 text-default-500 rounded-lg">
                        <li>한달 예상 유류비</li>
                        <li>기사 1명당 평균 운행거리</li>
                        <li>기사 1명당 평균 작업시간</li>
                        <li>기사 1명당 평균 방문지 수 외 5건</li>
                      </ol>
                    </div>
                    <div className="flex flex-col flex-1 overflow-hidden border rounded-lg bg-background h-96 border-default-300">
                      <div className="flex items-start justify-between p-4 text-lg font-bold">
                        <span>시나리오 비교하기 결과</span>
                        <X className="text-sm"></X>
                      </div>
                      <div className="flex flex-1 p-4 overflow-hidden">
                        <ScrollShadow visibility="bottom" className="flex-1 pb-4">
                          <table className="first:[&>tbody>tr>td]:text-default-400 [&>tbody>tr>th]:px-4 [&>tbody>tr>th]:py-2 [&>tbody>tr>td]:px-4 [&>tbody>tr>td]:py-2 text-sm font-normal text-left w-full">
                            <tbody>
                              <tr className="[&>th]:font-normal bg-default-50 sticky top-0">
                                <th>항목</th>
                                <th>시나리오 1</th>
                                <th>시나리오 2</th>
                                <th>시나리오 3</th>
                              </tr>
                              <tr>
                                <td>기사수</td>
                                <td>5</td>
                                <td className="bg-success-100">4</td>
                                <td className="bg-danger-100">6</td>
                              </tr>
                              <tr>
                                <td>방문지수</td>
                                <td>120개</td>
                                <td>120개</td>
                                <td>120개</td>
                              </tr>
                              <tr>
                                <td>운행거리</td>
                                <td className="bg-success-100">188km</td>
                                <td>198km</td>
                                <td className="bg-danger-100">208km</td>
                              </tr>
                              <tr>
                                <td>1인 평균 거리</td>
                                <td className="bg-success-100">45km</td>
                                <td>47km</td>
                                <td className="bg-danger-100">53km</td>
                              </tr>
                              <tr>
                                <td>운행시간</td>
                                <td>89시간</td>
                                <td className="bg-danger-100">90시간</td>
                                <td className="bg-success-100">76시간</td>
                              </tr>
                              <tr>
                                <td>1인 평균 시간</td>
                                <td className="bg-danger-100">3시간 12분</td>
                                <td className="bg-success-100">2시간 22분</td>
                                <td>2시간 44분</td>
                              </tr>
                              <tr>
                                <td>한달 예상 유류비</td>
                                <td className="bg-success-100">293,800원</td>
                                <td className="bg-danger-100">453,800원</td>
                                <td>333,800원</td>
                              </tr>
                              <tr>
                                <td>평균 작업량</td>
                                <td>88kg</td>
                                <td className="bg-success-100">56kg</td>
                                <td className="bg-danger-100">90kg</td>
                              </tr>
                              <tr>
                                <td>평균 방문지</td>
                                <td>31개</td>
                                <td className="bg-success-100">20개</td>
                                <td className="bg-danger-100">32개</td>
                              </tr>
                            </tbody>
                          </table>
                        </ScrollShadow>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  key={"button"}
                  className="ml-auto !cursor-pointer group"
                  title={
                    <div
                      onClick={() => {
                        onClose();
                        onSampleConfirmOpen();
                      }}
                      className="flex justify-between items-center p-2 h-full !transition-all ease-in-outs"
                    >
                      <div className="flex items-center space-x-2">
                        <div className="flex items-center justify-center w-10 rounded-full aspect-square bg-default-200">
                          <Circuitry className="text-2xl text-default-500" />
                        </div>
                        <div className="flex flex-col items-start">
                          <span className="text-xs text-default-400">방문이 처음이신가요?</span>
                          <span className="text-default-700">샘플 데이터로 체험해보세요</span>
                        </div>
                      </div>
                      <CaretRight className="transition-all group-hover:ml-2 text-default-800"></CaretRight>
                    </div>
                  }
                ></Tab>
              </Tabs>
            </ModalBody>
            <ModalFooter>
              <div className="flex items-center justify-between w-full align-middle">
                <Checkbox isSelected={isSelected} onValueChange={setIsSelected}>
                  다시보지 않기
                </Checkbox>
                <div className="flex items-center gap-2">
                  <Button
                    color="default"
                    variant="flat"
                    onPress={() => {
                      onClose();
                    }}
                  >
                    닫기
                  </Button>
                  <Button color="primary" isDisabled={tab === tabList[tabList.length - 1]} onPress={() => handleNextTab(tab)}>
                    다음
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default HelpModal;
