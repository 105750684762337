import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ScrollShadow,
  Select,
  SelectItem,
  CheckboxGroup,
  Checkbox,
  cn,
  Tooltip,
} from "@nextui-org/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../utils/PrivateApi";
import {
  ArrowCounterClockwise,
  Check,
  Clock,
  FolderOpen,
  MagnifyingGlass,
  PencilSimple,
  RoadHorizon,
} from "@phosphor-icons/react";
import {
  convertDuration,
  convertDistance,
  decomposeHangul,
  getCommaNum,
} from "../../utils/FormatUtils";
import { MapPin } from "@phosphor-icons/react/dist/ssr";

const TITLE_MAX_LENGTH = 30;

const OPTIONS = {
  NONE: "기본",
  DISTANCE: "운행거리균등",
  TIME: "작업시간균등",
  NODE: "방문지균등",
};

const Scenario = ({ routeOption, modalVisible, openModal, closeModal }) => {
  const queryClient = useQueryClient();

  /* 로드 */
  const [loadModalProps, setloadModalProps] = useState({
    id: "",
    name: "",
  });

  const loadScenario = useMutation({
    mutationFn: ({ scenarioId }) => {
      return axiosInstance.post("/api/v1/scenario/load", { scenarioId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["driver"] });
      queryClient.invalidateQueries({ queryKey: ["route"] });
      queryClient.invalidateQueries({ queryKey: ["optimize"] });
      closeModal("load");
    },
  });

  /** 시나리오 수정 */
  const [modifyText, setModifyText] = useState("");
  const [scenarioModify, setScenarioModify] = useState({
    id: null,
    prevTitle: null,
  });
  //변경전과 같거나 텍스트 입력이 안되어있으면 실패
  const modifyInvalid =
    modifyText === scenarioModify.prevTitle || modifyText.length < 1;
  const modifyInvalidMsg = () => {
    if (modifyText === scenarioModify.prevTitle)
      return "변경전 시나리오명과 동일합니다.";
    return "변경할 시나리오명을 입력해주세요";
  };
  const editScenario = useMutation({
    mutationFn: ({ id, name }) => {
      return axiosInstance.put("/api/v1/scenario/name", { id, name });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["scenario"] });
      closeModal("edit");
      openModal("listView");
    },
  });

  const deleteScenario = useMutation({
    mutationFn: (id) => {
      return axiosInstance.delete("/api/v1/scenario", {
        params: { scenarioIds: id },
        withCredentials: true,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["scenario"] });
      closeModal("delete");
      openModal("listView");
    },
  });

  /* 시나리오 비교 */
  const [searchText, setSearchText] = useState("");
  const [selectedScenario, setSelectedScenario] = useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const scenarioQuery = useQuery({
    queryKey: ["scenario"],
    queryFn: () => axiosInstance.get("/api/v1/scenario"),
    refetchInterval: 1000 * 60 * 5,
    select: (data) => data.data.data.scenarioList,
  });

  const filteredSearchScenario = scenarioQuery?.data
    ? scenarioQuery.data.filter((scenario) =>
        decomposeHangul(scenario.name.replace(/\s+/g, ""))
          .toLowerCase()
          .includes(
            decomposeHangul(searchText.replace(/\s+/g, "")).toLowerCase()
          )
      )
    : [];

  const columns = [
    { key: "name", label: "항목" },
    { key: "driverCnt", label: "기사수" },
    { key: "nodeCnt", label: "방문지 수" },
    { key: "totalDistance", label: "총 운행거리", convert: convertDistance },
    { key: "totalDuration", label: "총 운행시간", convert: convertDuration },
    {
      key: "avgDistance",
      label: "1인 평균 운행거리",
      convert: convertDistance,
    },

    {
      key: "avgDuration",
      label: "1인 평균 운행시간",
      convert: convertDuration,
    },
    {
      key: "avgItemQty",
      label: "1인 평균 작업량",
      convert: (value) => `${value.toFixed(2)}kg`,
    },
    {
      key: "avgNodeCnt",
      label: "1인 평균 방문지",
      convert: (value) => `${value.toFixed(1)}개`,
    },
    {
      key: "fuelCostPerMonth",
      label: "한달 예상 유류비",
      convert: (value) => `${value}원`,
    },
  ];

  const filteredSelectedScenario = scenarioQuery?.data
    ? scenarioQuery.data
        .filter((item) => selectedScenario.includes(item.id))
        .map((item) => {
          /*
        *이름, name
        *기사 수, driverCnt
        *방문지 수, nodeCnt
        *총 운행거리 totalDistance
        1인 평균 운행거리 avgDistance = totalDistance / driverCnt
        *총 운행시간 totalDuration
        1인 평균 운행시간  = avgDuration = totalDuration / driverCnt
        한달 예상 유류비  fuelCostPerMonth =  costPerKm * totalDistance / 1000
        1인 평균 작업량 avgItemQty =  totalItemQty / driverCnt
        1인 평균 방문지 avgNodeCnt = nodeCnt / driverCnt
        */
          item.avgDistance = item.totalDistance / item.driverCnt;
          item.avgDuration = item.totalDuration / item.driverCnt;
          item.fuelCostPerMonth = getCommaNum(item.cost * 30);
          item.avgItemQty = item.totalItemQty / item.driverCnt;
          item.avgNodeCnt = item.nodeCnt / item.driverCnt;
          return item;
        })
    : [];

  const uniqueValueColor = (key, value) => {
    const values = filteredSelectedScenario.map((item) => item[key]);
    const max = Math.max(...values);
    const min = Math.min(...values);
    const allValuesSame = values.every((v) => v === values[0]);

    if (allValuesSame) return ""; // If all values are the same, return no color
    if (value === max && values.filter((v) => v === max).length === 1)
      return "bg-danger-100";
    if (value === min && values.filter((v) => v === min).length === 1)
      return "bg-success-100";
    return "";
  };

  const renderCellContent = (item, column) => {
    const value = item[column.key];
    const convertedValue = column.convert ? column.convert(value) : value;
    return convertedValue;
  };

  /* 시나리오 저장 */
  const handleSaveScenario = () => {
    saveScenario.mutate({ name: saveTitle, routeOption });
  };
  const [saveTitle, setSaveTitle] = React.useState("");

  const saveScenario = useMutation({
    mutationFn: ({ name, routeOption }) => {
      return axiosInstance.post("/api/v1/scenario", { name, routeOption });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["scenario"] });
      closeModal("save");
    },
  });

  return (
    <>
      <Modal isOpen={modalVisible.load} onClose={() => closeModal("load")}>
        <ModalContent>
          <ModalHeader>{"시나리오 불러오기"}</ModalHeader>
          <ModalBody>
            <p>시나리오를 불러오시겠습니까?</p>
            <p>이전에 업로드한 데이터는 사라집니다.</p>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="flat"
              onClick={() => {
                closeModal("load");
                openModal("listView");
              }}
            >
              돌아가기
            </Button>
            <Button
              color="primary"
              onClick={() => {
                loadScenario.mutate({ scenarioId: loadModalProps.id });
              }}
            >
              불러오기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalVisible.delete}
        isDismissable={deleteScenario.isPending}
        onClose={() => closeModal("delete")}
      >
        <ModalContent>
          <ModalHeader>{"시나리오 삭제하기"}</ModalHeader>
          <ModalBody>
            <p>{scenarioModify.prevTitle} 시나리오를 삭제하시겠습니까?</p>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="flat"
              isDisabled={deleteScenario.isPending}
              onClick={() => {
                closeModal("delete");
                openModal("edit");
              }}
            >
              돌아가기
            </Button>
            <Button
              color="danger"
              isLoading={deleteScenario.isPending}
              isDisabled={deleteScenario.isPending}
              onClick={() => {
                deleteScenario.mutate(scenarioModify.id);
              }}
            >
              삭제하기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeButton
        isDismissable={editScenario.isPending || deleteScenario.isPending}
        isOpen={modalVisible.edit}
        onClose={() => closeModal("edit")}
        classNames={{ header: "pt-8 pb-2" }}
      >
        <ModalContent>
          <ModalHeader>시나리오명 수정</ModalHeader>
          <ModalBody>
            <div>
              <Input
                label="시나리오 명"
                placeholder={scenarioModify.prevTitle}
                value={modifyText}
                onChange={(e) => setModifyText(e.target.value)}
              />
              <p className="h-6 p-1 text-xs text-default-400">
                {modifyInvalidMsg()}
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="flat"
              className="mr-auto"
              color="danger"
              isDisabled={editScenario.isPending || deleteScenario.isPending}
              onClick={() => {
                openModal("delete");
                closeModal("edit");
              }}
            >
              삭제
            </Button>
            <Button
              variant="flat"
              isDisabled={editScenario.isPending || deleteScenario.isPending}
              onClick={() => {
                openModal("listView");
                closeModal("edit");
              }}
            >
              돌아가기
            </Button>
            <Button
              isDisabled={
                modifyInvalid ||
                editScenario.isPending ||
                deleteScenario.isPending
              }
              isLoading={editScenario.isPending}
              color="primary"
              onClick={() => {
                editScenario.mutate({
                  id: scenarioModify.id,
                  name: modifyText,
                });
              }}
            >
              수정
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeButton
        isOpen={modalVisible.save}
        onClose={() => closeModal("save")}
        classNames={{ header: "pt-8 pb-2" }}
      >
        <ModalContent>
          <ModalHeader>시나리오 저장</ModalHeader>
          <ModalBody>
            <Input
              maxLength={TITLE_MAX_LENGTH}
              label="시나리오 제목"
              value={saveTitle}
              onValueChange={setSaveTitle}
              isInvalid={saveTitle}
              endContent={
                <div className="text-xs text-foreground-400">
                  {saveTitle.length}/{TITLE_MAX_LENGTH}
                </div>
              }
            ></Input>
            <p className="text-sm text-default-400">
              저장된 시나리오는 <b>상단 메뉴 &gt; 시나리오 비교하기</b>에서
              <br />
              확인하실 수 있습니다.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button variant="flat" onClick={() => closeModal("save")}>
              취소
            </Button>
            <Button
              color="primary"
              isLoading={saveScenario.isPending}
              onClick={handleSaveScenario}
              isDisabled={saveTitle.length === 0}
            >
              저장
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeButton
        isOpen={modalVisible.view}
        onClose={() => closeModal("view")}
        classNames={{ header: "pt-8 pb-2" }}
        size="5xl"
      >
        <ModalContent>
          <ModalHeader>시나리오 비교</ModalHeader>
          <ModalBody>
            <ScrollShadow orientation="horizontal">
              {columns.map((col, idx) => (
                <div
                  key={idx}
                  className="flex text-sm first:bg-default-200 [&:nth-child(1)]:bg-default-100"
                >
                  <div className="px-2 py-2 min-w-32 max-w-32 text-default-400">
                    {col.label}
                  </div>
                  {filteredSelectedScenario.map((item, index) => (
                    <div
                      key={index}
                      className={`px-2 py-2 flex-1 min-w-32 truncate ${uniqueValueColor(
                        col.key,
                        item[col.key]
                      )}`}
                    >
                      {renderCellContent(item, col)}
                    </div>
                  ))}
                </div>
              ))}
            </ScrollShadow>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="flat"
              onClick={() => {
                closeModal("view");
                openModal("listView");
              }}
            >
              돌아가기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        closeButton
        isOpen={modalVisible.listView}
        onClose={() => closeModal("listView")}
        classNames={{ header: "pt-8 pb-2" }}
      >
        <ModalContent>
          <ModalHeader>시나리오 목록</ModalHeader>
          <ModalBody>
            <div className="flex gap-2">
              <Input
                value={searchText}
                onValueChange={setSearchText}
                fullWidth
                placeholder="시나리오 명을 검색하세요"
                startContent={
                  <MagnifyingGlass
                    weight="bold"
                    className="text-xl text-default-500"
                  />
                }
              ></Input>
              <Select
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="전체"
                className="w-32 max-w-32 min-w-32"
              >
                {Object.keys(OPTIONS).map((key, i) => (
                  <SelectItem key={key}>{OPTIONS[key]}</SelectItem>
                ))}
              </Select>
            </div>
            <div className="text-sm text-default-500">
              총 시나리오 {scenarioQuery?.data ? scenarioQuery.data.length : 0}
              건 등록됨
            </div>
            <ScrollShadow className="space-y-3 h-96">
              <CheckboxGroup
                value={selectedScenario}
                onChange={setSelectedScenario}
              >
                {scenarioQuery.isSuccess
                  ? filteredSearchScenario
                      .filter((item) => {
                        if (searchValue === "") {
                          return true;
                        }
                        if (item.routeOption === searchValue) {
                          return true;
                        } else {
                          return false;
                        }
                      })
                      .map((item, key) => (
                        <Checkbox
                          key={item.id}
                          value={item.id}
                          // icon={<Check weight="bold" />}
                          size="sm"
                          classNames={{
                            label: "flex flex-1",
                            base: cn(
                              "inline-flex max-w-md w-full m-0 shadow-sm",
                              "hover:bg-default-50 items-center justify-start",
                              "cursor-pointer gap-2 p-4 border-l-4 border-default-200",
                              "data-[selected=true]:border-primary-400"
                            ),
                          }}
                        >
                          <div className="flex-1">
                            <div>{item.name}</div>
                            <div className="flex gap-2 text-default-500">
                              <div className="flex items-center gap-1">
                                <MapPin
                                  weight="fill"
                                  className="text-default-300"
                                ></MapPin>
                                <span>{item.nodeCnt}개</span>
                              </div>
                              <div className="flex items-center gap-1">
                                <Clock
                                  weight="fill"
                                  className="text-default-300"
                                ></Clock>
                                <span>
                                  {convertDuration(item.totalDuration)}
                                </span>
                              </div>
                              <div className="flex items-center gap-1">
                                <RoadHorizon
                                  weight="fill"
                                  className="text-default-300"
                                ></RoadHorizon>
                                <span>
                                  {convertDistance(item.totalDistance)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="group-data-[hover=true]:flex hidden items-center gap-2">
                            <Button
                              size="sm"
                              variant="flat"
                              isIconOnly
                              onClick={() => {
                                closeModal("listView");
                                openModal("load");
                                setloadModalProps({
                                  id: item.id,
                                  name: item.name,
                                });
                              }}
                            >
                              <FolderOpen
                                weight="fill"
                                className="text-lg text-default-400"
                              ></FolderOpen>
                            </Button>
                            <Button
                              size="sm"
                              variant="flat"
                              isIconOnly
                              onClick={() => {
                                closeModal("listView");
                                openModal("edit");
                                setModifyText("");
                                setScenarioModify({
                                  id: item.id,
                                  prevTitle: item.name,
                                });
                              }}
                            >
                              <PencilSimple
                                weight="fill"
                                className="text-lg text-default-400"
                              ></PencilSimple>
                            </Button>
                          </div>
                        </Checkbox>
                      ))
                  : null}
              </CheckboxGroup>
            </ScrollShadow>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                closeModal("listView");
                openModal("view");
              }}
              color="primary"
              isDisabled={selectedScenario.length < 2}
            >
              <div className="min-w-6">{selectedScenario.length}개</div>
              시나리오 비교하기
            </Button>
            <Tooltip content={"시나리오 선택 전체 해제"}>
              <Button
                isDisabled={selectedScenario.length < 1}
                className="mr-auto"
                variant="flat"
                isIconOnly
                onClick={() => {
                  setSelectedScenario([]);
                }}
              >
                <ArrowCounterClockwise weight="bold"></ArrowCounterClockwise>
              </Button>
            </Tooltip>
            <Button variant="flat" onClick={() => closeModal("listView")}>
              닫기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Scenario;
