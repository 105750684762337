import React from "react";
import { Table } from "@phosphor-icons/react";
import { ScrollShadow, Button } from "@nextui-org/react";
import { Card, CardBody } from "@nextui-org/card";

const PlacesContent = ({ data, onRouteExConfirmOpen, handleFlyToCoordinates, reDrawMarker }) => {
  const [selectedPlace, setSelectedPlace] = React.useState(null);
  return (
    <div className="flex flex-col p-4 space-y-4 border-b grow bg-default-50 border-default-200">
      <div className="flex items-center justify-between">
        <span className="font-semibold text-default-800">방문지 목록</span>
        <Button isIconOnly size="sm" className="bg-default-200 text-default-600 max-w-fit" onClick={onRouteExConfirmOpen}>
          <Table className="text-xl" />
        </Button>
      </div>
      <ScrollShadow hideScrollBar className="relative flex-1">
        <div className="absolute w-full space-y-3">
          {data.length > 0 &&
            data.map((route, i) => (
              <Card
                radius="none"
                className={"shadow-sm border-l-4 bg-background " + (selectedPlace === i ? "border-blue-400" : "border-default-200")}
                shadow="none"
                fullWidth={true}
                isPressable={true}
                key={i}
                onPress={() => {
                  setSelectedPlace(i);
                  reDrawMarker(i);
                  handleFlyToCoordinates(route.x, route.y, route);
                }}
              >
                <CardBody>
                  <div className="w-full truncate">
                    {i + 1}. {route.address}
                  </div>
                </CardBody>
              </Card>
            ))}
        </div>
      </ScrollShadow>
    </div>
  );
};

export default PlacesContent;
