import React, { useState, useEffect } from "react";
import Card from "./Card";
import { Circle, Package, ThermometerSimple, MapPin, HourglassMedium, Note } from "@phosphor-icons/react";
import { convertDistance, convertDuration, convertTimeToHHmm, getQtyUnitName, getVehicleTypeName, stringToPrettyColor } from "../utils/FormatUtils";
import { motion, AnimatePresence } from "framer-motion";

function RoutePanel({ data, selectedId, handleFlyToCoordinates }) {
  const [selctedDriver, setSelectedDriver] = useState(null);

  useEffect(() => {
    if (data && selectedId) {
      const selected = data.find((d) => d.id === selectedId);
      setSelectedDriver(selected);
    }
  }, [data, selectedId]);

  return (
    <AnimatePresence>
      {selectedId && selctedDriver && (
        <motion.div
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -20, opacity: 0 }}
          transition={{ duration: 0.1, ease: "easeOut" }}
          className="route-panel absolute w-[442px] left-0 top-0 h-full z-10 flex flex-col bg-default-50 border-r border-default-200"
        >
          <div className="flex items-center px-4 pt-6 mb-4">
            <Circle weight="fill" color={stringToPrettyColor("hex", selctedDriver.name)} className="mr-2"></Circle>
            <div className="font-bold text-default-800">{selctedDriver.name}</div>
            <div className="flex ml-auto">
              <i className="text-xl ph ph-x"></i>
            </div>
          </div>
          <div className="flex flex-col px-4">
            <span className="text-sm">경로 최적화 결과</span>
            <div className="flex flex-wrap items-center justify-center gap-3 px-2 py-3">
              <div className="flex flex-col items-center">
                <div>{selctedDriver.nodeCnt}곳</div>
                <div className="text-xs text-default-400">경유지</div>
              </div>
              <div className="h-6 border-l border-default-200"></div>
              <div className="flex flex-col items-center">
                <div>{convertDistance(selctedDriver.totalDistance)}</div>
                <div className="text-xs text-default-400">이동거리</div>
              </div>
              <div className="h-6 border-l border-default-200"></div>
              <div className="flex flex-col items-center">
                <div>{convertDuration(selctedDriver.totalDuration)}</div>
                <div className="text-xs text-default-400">이동시간</div>
              </div>
              <div className="h-6 border-l border-default-200"></div>
              <div className="flex flex-col items-center">
                <div>
                  {selctedDriver.totalItemQty}
                  {getQtyUnitName(selctedDriver.itemQtyUnit)}
                </div>
                <div className="text-xs text-default-400">총 적재량</div>
              </div>
              <div className="h-6 border-l border-default-200"></div>
              <div className="flex flex-col items-center">
                <div>{selctedDriver.totalJobDuration + "분"}</div>
                <div className="text-xs text-default-400">총 작업시간</div>
              </div>
              <div className="h-6 border-l border-default-200"></div>
              <div className="flex flex-col items-center">
                <div>{selctedDriver.avgJobDuration + "분"}</div>
                <div className="text-xs text-default-400">평균 작업시간</div>
              </div>
              <div className="h-6 border-l border-default-200"></div>
              <div className="flex flex-col items-center">
                <div>{getVehicleTypeName(selctedDriver.vehicleType)}</div>
                <div className="text-xs text-default-400">차종</div>
              </div>
            </div>
          </div>
          <div className="flex flex-1 overflow-y-scroll border-t border-default-200">
            <div className="flex-1 px-4 py-3 space-y-2 text-sm">
              {selctedDriver.routeVisitList.map((visit, index) => (
                <div key={index} className="flex cursor-pointer">
                  <div className="flex flex-col flex-none">
                    <div className="flex items-center w-20 py-3">
                      <MapPin weight="fill" className="mr-2" color={stringToPrettyColor("hex", selctedDriver.name)}></MapPin>
                      <i className="mr-2 text-blue-400 ph-fill ph-circle"></i>
                      <span>{convertTimeToHHmm(visit.arrivalTime)}</span>
                    </div>
                    <div className="flex-1 border-l border-default-200 ml-1.5"></div>
                  </div>
                  <Card hoverEffect={true} handleClick={() => handleFlyToCoordinates(visit.job.node.x, visit.job.node.y, { ...visit.job, ...visit.job.node })} className="flex-1 p-3 bg-white border rounded-md">
                    <div className="flex items-start mb-2">
                      <div className="flex items-center justify-center w-5 h-5 p-1 mr-1 text-sm border rounded-md border-default-200 text-default-400 bg-default-100">{index + 1}</div>
                      <span className="">{visit.job.node.address}</span>
                    </div>
                    <div className="flex flex-wrap text-xs text-default-400 gap-x-12 gap-y-1">
                      <div className="flex items-center">
                        <ThermometerSimple className="mr-1" />
                        <span>냉장보관</span>
                      </div>
                      <div className="flex items-center">
                        <Package className="mr-1" />
                        <span>
                          {visit.job.itemQty}
                          {getQtyUnitName(visit.job.itemQtyUnit)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <HourglassMedium className="mr-1" />
                        <span>{visit.job.jobDuration}분 작업</span>
                      </div>
                      <div className="flex items-center">
                        <Note className="mr-1" />
                        <span>{visit.job.memo}</span>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default RoutePanel;
