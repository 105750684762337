import { React, useCallback, useState, useImperativeHandle, forwardRef, useEffect, useRef, memo } from "react";
import { Map, MapBox } from "react-map-gl";
import DeckGL from "@deck.gl/react/typed";
import { FlyToInterpolator } from "@deck.gl/core";
import { IconLayer, PathLayer } from "@deck.gl/layers";
import { getQtyUnitName, stringToPrettyColor } from "../utils/FormatUtils";
import { WebMercatorViewport } from "@deck.gl/core";
import { Package, HourglassMedium, Truck, Note } from "@phosphor-icons/react";
import { useTheme } from "next-themes";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

// Mapbox Access Token 설정
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const MAPBOX_STYLE = process.env.REACT_APP_MAPBOX_STYLE;
const initialViewState = {
  latitude: 37.55384,
  longitude: 126.924262,
  zoom: 10,
  bearing: 0,
  pitch: 0,
  altitude: 1.5,
  maxZoom: 20,
  minZoom: 0,
  maxPitch: 60,
  minPitch: 0,
  normalize: true,
  position: [0, 0, 0],
};

const initialController = {
  doubleClickZoom: false,
  touchRotate: false,
};
let isHovering = false;

const VusMap = forwardRef((props, ref) => {
  const { theme } = useTheme();
  const mapbox_style = theme === "dark" ? "mapbox://styles/mapbox/dark-v11" : MAPBOX_STYLE;
  // const mapbox_style = MAPBOX_STYLE;
  const [viewState, setViewState] = useState(initialViewState);
  const [layers, setLayers] = useState([]);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const prevClickedObject = useRef(null);
  const tooltipRef = useRef(null);
  const MapRef = useRef(null);
  const onViewStateChange = useCallback(({ viewState: newView }) => {
    setViewState(newView);
  }, []);
  useEffect(() => {
    if (tooltipInfo !== null) {
      const [newPosX, newPosY] = new WebMercatorViewport(viewState).project([tooltipInfo.x, tooltipInfo.y]);
      if (tooltipRef.current) {
        tooltipRef.current.style.left = `${newPosX}px`;
        tooltipRef.current.style.top = `${newPosY}px`;
      }
    }
  }, [viewState, tooltipInfo]);

  const mapRefCallback = useCallback((ref) => {
    if (ref !== null) {
      const map = ref;
      const language = new MapboxLanguage();
      map.addControl(language);
    }
  }, []);
  const handleMarkerClick = (e) => {
    let d = e.object;
    if (d.job) {
      d = {
        ...d,
        ...d.job.node,
        ...d.job,
      };
    }
    console.log(tooltipInfo, d);
    if (prevClickedObject.current === e.object) {
      setTooltipInfo(null);
      prevClickedObject.current = null;
      return;
    }
    prevClickedObject.current = e.object;
    setTooltipInfo({
      address: d.address,
      memo: d.memo,
      x: d.x,
      y: d.y,
      itemQty: d.itemQty,
      itemQtyUnit: d.itemQtyUnit,
      jobDuration: d.jobDuration,
      vehicleType: d.vehicleType || d.requireVehicleType,
    });
  };

  const flyToCenter = (coordinatesList) => {
    const centerLongitude = (Math.min(...coordinatesList.map((coord) => coord[0])) + Math.max(...coordinatesList.map((coord) => coord[0]))) / 2;
    const centerLatitude = (Math.min(...coordinatesList.map((coord) => coord[1])) + Math.max(...coordinatesList.map((coord) => coord[1]))) / 2;
    //최적의 줌 레벨 계산

    if (coordinatesList.length === 1) {
      flyToCoordinates(centerLongitude, centerLatitude);
      return;
    }
    const bounds = coordinatesList.reduce(
      (acc, coord) => {
        return {
          minLng: Math.min(acc.minLng, coord[0]),
          minLat: Math.min(acc.minLat, coord[1]),
          maxLng: Math.max(acc.maxLng, coord[0]),
          maxLat: Math.max(acc.maxLat, coord[1]),
        };
      },
      {
        minLng: Infinity,
        minLat: Infinity,
        maxLng: -Infinity,
        maxLat: -Infinity,
      }
    );
    try {
      const zoom = new WebMercatorViewport(viewState).fitBounds(
        [
          [bounds.minLng, bounds.minLat],
          [bounds.maxLng, bounds.maxLat],
        ],
        {
          padding: 200,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      ).zoom;
      flyToCoordinates(centerLongitude, centerLatitude, zoom);
    } catch (e) {
      flyToCoordinates(centerLongitude, centerLatitude);
    }
  };

  const flyToCoordinates = useCallback((longitude, latitude, zoom) => {
    //FlyToInterpolator를 사용하여 지도 이동
    setViewState((prev) => {
      return {
        ...prev,
        longitude,
        latitude,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        pitch: 0,
        zoom: zoom ? zoom : prev.zoom,
        transitionEasing: (t) => 1 - Math.pow(1 - t, 5),
      };
    });
  }, []);

  const addLayer = useCallback((layer) => {
    setLayers((prevLayers) => [...prevLayers, layer]);
  }, []);

  const clearLayers = useCallback(() => {
    setLayers([]);
    setTooltipInfo(null);
  }, []);

  const removeLayer = useCallback((id) => {
    setLayers((prevLayers) => prevLayers.filter((layer) => layer.id !== id));
  }, []);

  const toggleLayerVisibility = useCallback((id) => {
    setLayers((prevLayers) => prevLayers.map((layer) => (layer.id === id ? { ...layer, visible: !layer.visible } : layer)));
  }, []);

  const addRoutePathLayer = useCallback(
    (route) => {
      // 경로 데이터가 없으면 레이어 추가하지 않음
      if (!route.pathJson) return;
      const pathLayer = new PathLayer({
        id: `path-${route.id}`,
        data: [
          {
            path: JSON.parse(route.pathJson),
          },
        ],
        getPath: (d) => d.path,
        getColor: (d) => stringToPrettyColor("rgbArray", route.name).map((c) => Math.max(0, Math.min(255, c - c * 0.2))),
        getWidth: 8,
        jointRounded: true,
        capRounded: true,
        widthMaxPixels: 8,
        widthMinPixels: 8,
      });

      addLayer(pathLayer);
    },
    [addLayer]
  );

  const addRouteMarkerLayer = useCallback(
    (route) => {
      route.routeVisitList = route.routeVisitList.map((visit, index) => ({
        ...visit,
        index: index + 1,
      }));
      const markerLayer = new IconLayer({
        id: `marker-${route.id}`,
        onClick: (e) => handleMarkerClick(e),
        pickable: true,
        data: route.routeVisitList,
        getIcon: (d) => {
          const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 32">
              <path fill="${stringToPrettyColor("hex", route.name)}" d="m12,31.31C10.07,29.45.5,19.84.5,12,.5,5.66,5.66.5,12,.5s11.5,5.16,11.5,11.5c0,7.84-9.57,17.45-11.5,19.31Z"/>
              <text fill="white" font-weight="700" font-size="13px" font-family="SpoqaHanSansNeo-Bold, 'Spoqa Han Sans Neo'" dominant-baseline="middle" text-anchor="middle"><tspan x="50%" y="47%">${d.index}</tspan></text>
            </svg>`;
          return {
            url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`,
            x: 0,
            y: 0,
            width: 240,
            height: 320,
            anchorY: 320,
          };
        },
        getPosition: (d) => [d.job.node.x, d.job.node.y],
        getSize: () => 52,
        loadOptions: {
          imagebitmap: {
            resizeWidth: 240,
            resizeHeight: 320,
            premultiplyAlpha: "premultiply",
          },
        },
        parameters: {
          blendFunc: [1, 771], // GL.ONE, GL.ONE_MINUS_SRC_ALPHA
        },
        textureParameters: {
          [10240]: 9728,
          [10241]: 9985,
          [10242]: 33071,
          [10243]: 33071,
        },
      });

      addLayer(markerLayer);
    },
    [addLayer]
  );

  const addMarkerLayer = useCallback(
    (routeList) => {
      routeList = routeList.map((route, index) => ({
        ...route,
        index: index + 1,
      }));
      const markerLayer = new IconLayer({
        id: `default-marker`,
        onClick: (e) => handleMarkerClick(e),
        pickable: true,
        data: routeList,
        getIcon: (d) => {
          const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 32">
              <path fill="black" d="m12,31.31C10.07,29.45.5,19.84.5,12,.5,5.66,5.66.5,12,.5s11.5,5.16,11.5,11.5c0,7.84-9.57,17.45-11.5,19.31Z"/>
              <text fill="white" font-weight="700" font-size="13px" font-family="SpoqaHanSansNeo-Bold, 'Spoqa Han Sans Neo'" dominant-baseline="middle" text-anchor="middle"><tspan x="50%" y="47%">${d.index}</tspan></text>
            </svg>`;
          return {
            url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`,
            x: 0,
            y: 0,
            width: 240,
            height: 320,
            anchorY: 320,
          };
        },
        getPosition: (d) => [d.x, d.y],
        getSize: () => 52,
        loadOptions: {
          imagebitmap: {
            resizeWidth: 240,
            resizeHeight: 320,
            premultiplyAlpha: "premultiply",
          },
        },
        parameters: {
          blendFunc: [1, 771], // GL.ONE, GL.ONE_MINUS_SRC_ALPHA
        },
        textureParameters: {
          [10240]: 9728,
          [10241]: 9985,
          [10242]: 33071,
          [10243]: 33071,
        },
      });

      addLayer(markerLayer);
    },
    [addLayer]
  );

  const addNewMarkerLayer = useCallback(
    (data) => {
      data.routeList = data.routeList.map((route, index) => ({
        ...route,
        index: index + 1,
      }));
      const indexToMove = data.routeList.findIndex((route) => data.id === route.index - 1);
      if (indexToMove !== -1) {
        // 해당 route를 배열에서 제거
        const [routeToMove] = data.routeList.splice(indexToMove, 1);
        // 배열의 끝에 다시 추가
        data.routeList.push(routeToMove);
        console.log(routeToMove);
      }
      const markerLayer = new IconLayer({
        id: `default-marker`,
        onClick: (e) => handleMarkerClick(e),
        pickable: true,
        data: data.routeList,
        getIcon: (d) => {
          const svg = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 32">
              <path fill="${data.id === d.index - 1 ? "#006FEE" : "black"}" d="m12,31.31C10.07,29.45.5,19.84.5,12,.5,5.66,5.66.5,12,.5s11.5,5.16,11.5,11.5c0,7.84-9.57,17.45-11.5,19.31Z"/>
              <text fill="white" font-weight="700" font-size="13px" font-family="SpoqaHanSansNeo-Bold, 'Spoqa Han Sans Neo'" dominant-baseline="middle" text-anchor="middle"><tspan x="50%" y="47%">${d.index}</tspan></text>
            </svg>`;
          return {
            url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`,
            x: 0,
            y: 0,
            width: 240,
            height: 320,
            anchorY: 320,
          };
        },
        getPosition: (d) => [d.x, d.y],
        getSize: () => 52,
        loadOptions: {
          imagebitmap: {
            resizeWidth: 240,
            resizeHeight: 320,
            premultiplyAlpha: "premultiply",
          },
        },
        parameters: {
          blendFunc: [1, 771], // GL.ONE, GL.ONE_MINUS_SRC_ALPHA
        },
        textureParameters: {
          [10240]: 9728,
          [10241]: 9985,
          [10242]: 33071,
          [10243]: 33071,
        },
      });

      addLayer(markerLayer);
    },
    [addLayer]
  );

  useImperativeHandle(ref, () => ({
    onViewStateChange,
    setTooltipInfo,
    clearLayers,
    addLayer,
    removeLayer,
    toggleLayerVisibility,
    addRouteMarkerLayer,
    addRoutePathLayer,
    addMarkerLayer,
    flyToCoordinates,
    flyToCenter,
    addNewMarkerLayer,
  }));

  return (
    <>
      <DeckGL
        onHover={({ object }) => (isHovering = Boolean(object))}
        getCursor={({ isDragging }) => (isDragging ? "grabbing" : isHovering ? "pointer" : "grab")}
        useDevicePixels={2}
        doubleClickZoom={false}
        initialViewState={viewState}
        controller={initialController}
        onViewStateChange={onViewStateChange}
        layers={layers}
      >
        {tooltipInfo && (
          // address, itemQty, itemQtyUnit, jobDuration
          <div ref={tooltipRef} className="map-tooltip">
            <div className="text-sm font-medium">{tooltipInfo.address}</div>

            <div className="flex text-xs gap-x-8">
              <div className="flex items-center">
                <Truck className="mr-1" />
                <div>
                  <div>
                    {tooltipInfo.itemQty}
                    {getQtyUnitName(tooltipInfo.itemQtyUnit)}
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <Note className="mr-1" />
                <div>{tooltipInfo.memo}</div>
              </div>
              <div className="flex items-center">
                <HourglassMedium className="mr-1" />
                <div>{tooltipInfo.jobDuration}분</div>
              </div>
            </div>
          </div>
        )}
        <Map ref={mapRefCallback} preserveDrawingBuffer={false} styleDiffing={false} reuseMaps={true} mapboxAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={mapbox_style} locale={"ko-KR"} />
      </DeckGL>
    </>
  );
});

export default memo(VusMap);
