import React, { useState } from "react";
import { MicrosoftExcelLogo, Question } from "@phosphor-icons/react";
import * as XLSX from "xlsx";

const Dropzone = ({ onDataExport, setIsLoading }) => {
  const [isActive, setActive] = useState(false);

  const handleDragStart = (event) => {
    event.preventDefault();
    if (event.currentTarget === event.target) {
      setActive(true);
    }
  };
  const handleDragEnd = (event) => {
    event.preventDefault();
    if (event.currentTarget === event.target) {
      setActive(false);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    setActive(false);
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFileRead(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    event.target.value = null;
    handleFileRead(file);
  };

  const handleFileRead = async (file) => {
    if (!file) return;
    if (!file.name.toLowerCase().match(/\.(xls|xlsx|csv)$/)) {
      alert("엑셀 파일만 업로드 가능합니다.");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = reader.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        range: 1,
        header: ["address", "jobDate", "y", "x", "jobStartTime", "jobEndTime", "jobDuration", "itemQty", "itemQtyUnit", "requireVehicleType", "memo"],
      });

      // 정합성 테스트
      // memo가 없는 경우 빈 문자열로 채-움
      jsonData.forEach((row) => {
        if (!row.memo) row.memo = "-";
      });

      // console.log(jsonData);
      onDataExport(jsonData);
      setIsLoading(true);

      // axios.post("/api/route/optimization", jsonData);
      // const response = await axios.post("/api/route", jsonData);
      console.log("엑셀 데이터 업로드 완료", jsonData);
      setIsLoading(false);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <>
      <label
        className={(isActive ? "border-blue-400 bg-blue-400 bg-opacity-10" : "border-default-300") + " cursor-pointer border rounded-lg border-dashed h-32 flex flex-col justify-center items-center text-default-400 mb-2"}
        onDragEnter={handleDragStart}
        onDragOver={handleDragOver}
        onDragLeave={handleDragEnd} // dragend 핸들러 추가
        onDrop={handleDrop}
        onMouseEnter={handleDragStart}
        onMouseLeave={handleDragEnd}
      >
        <input onChange={handleFileChange} type="file" accept=".xls,.xlsx,.csv" className="hidden" />
        <MicrosoftExcelLogo weight="fill" size={48} className="pointer-events-none" />
        <div className="text-xs pointer-events-none">클릭 혹은 파일을 이곳에 드롭하세요.</div>
        <div className="text-xs pointer-events-none">단일 첨부만 가능합니다.</div>
      </label>
    </>
  );
};

export default Dropzone;
