import { React, useEffect, useRef, useState } from "react";
import { DataTable, VusMap, Dropzone, DriverCardList, RoutePanel, Loading, Header, VisitCardList, Modal } from "../components";
import { MapPin } from "@phosphor-icons/react/dist/ssr";
import { FlowArrow, Upload } from "@phosphor-icons/react";
import axiosInstance from "../utils/PrivateApi";
import { motion, AnimatePresence } from "framer-motion";
import * as XLSX from "xlsx";
import { Accordion, AccordionItem, ScrollShadow, Button } from "@nextui-org/react";

function Vrp() {
  const mapRef = useRef(null);
  const [resultdata, setResultData] = useState(null);
  const [importExcelData, setImportExcelData] = useState(null);
  const [importData, setImportData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  function downloadFile() {
    // 파일 URL 생성
    const fileUrl = `${process.env.PUBLIC_URL}/sample.xlsx`;

    // `<a>` 태그를 동적으로 생성하고 클릭 이벤트를 트리거하여 파일 다운로드
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "sample.xlsx"); // 다운로드될 파일 이름 설정
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const objectToExcel = (data, fileName) => {
    let list = data.map((route) => {
      return route.routeVisitList.map((visit, index) => {
        return {
          name: route.name,
          index: index + 1,
          address: visit.job.node.address,
          jobDate: visit.job.jobDate,
          y: visit.job.node.y,
          x: visit.job.node.x,
          arrivalTime: visit.arrivalTime,
          departureTime: visit.departureTime,
          jobDuration: visit.job.jobDuration,
          itemQty: visit.job.itemQty,
          itemQtyUnit: visit.job.itemQtyUnit,
          vehicleType: route.vehicleType,
          memo: visit.job.memo,
        };
      });
    });

    list = list.flat();
    const heading = [["기사명", "방문순서", "방문지 주소", "운행일", "위도", "경도", "도착시간", "출발시간", "작업시간", "적재량", "단위", "차종", "메모"]];
    console.log("list", list);
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, heading);
    XLSX.utils.sheet_add_json(worksheet, list, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  const handleObjectToExcel = () => {
    objectToExcel(resultdata, "output.xlsx");
  };

  const onSelectedIdChange = (id) => {
    if (id === selectedId) return;
    setSelectedId(id);
    if (id) {
      const selectedRoute = resultdata.find((route) => route.id === id);
      mapRef.current?.setTooltipInfo(null);
      if (selectedRoute.pathJson) {
        mapRef.current?.flyToCenter(JSON.parse(selectedRoute.pathJson));
      }
    }
  };

  const handleFlyToCoordinates = (x, y, tooltipInfo) => {
    mapRef.current?.flyToCoordinates(x, y);
    mapRef.current?.setTooltipInfo(tooltipInfo);
  };

  const handleSetExcelData = (data) => {
    console.log("Data uploaded: ", data);
    setImportExcelData(data);
    setIsExcelModalOpen(true);
  };

  const handlePostData = () => {
    setIsExcelModalOpen(false);
    setImportData(null);
    setResultData(null);
    axiosInstance.post("/api/v1/route", importExcelData).then((response) => {
      console.log("Data uploaded: ", response);
      if (response.data.code === "FAIL") {
        alert(response.data.msg);
      } else if (response.data.code === "SUCC") {
        setImportData(response.data.data.routeList);
      }
    });
  };

  const handleOptimize = () => {
    setIsLoading(true);

    axiosInstance.post("/api/v1/route/vrp").then((response) => {
      if (response.data.data) {
        let optimizedData = response.data.data.routeList;
        optimizedData = optimizedData.map((route) => {
          if (!route.pathJson) {
            route.pathJson = JSON.stringify([[route.routeVisitList[0].job.node.x, route.routeVisitList[0].job.node.y]]);
          }
          return route;
        });

        setResultData(optimizedData);
      } else {
        alert("경로 최적화에 실패했습니다.");
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    console.log("excel", isExcelModalOpen);
  }, [isExcelModalOpen]);

  useEffect(() => {
    if (resultdata) {
      console.log("Rendering optimized routdes...");
      mapRef.current?.clearLayers();

      resultdata.map((route) => {
        mapRef.current?.addRoutePathLayer(route);
      });

      resultdata.map((route) => {
        mapRef.current?.addRouteMarkerLayer(route);
      });

      const pathList = resultdata
        .map((route) => {
          return JSON.parse(route.pathJson);
        })
        .flat();

      mapRef.current?.flyToCenter(pathList);

      setIsLoading(false);
    }
  }, [resultdata]);

  useEffect(() => {
    if (importData) {
      setIsLoading(true);
      console.log("Rendering imported routes...");
      setResultData(null);
      mapRef.current?.clearLayers();
      mapRef.current?.addMarkerLayer(importData);
      mapRef.current?.flyToCenter(importData.map((node) => [node.x, node.y]));
      setIsLoading(false);
    } else {
      setResultData(null);
      mapRef.current?.clearLayers();
    }
  }, [importData]);

  //첫 로딩시 데이터 불러오기
  useEffect(() => {
    axiosInstance.get("/api/v1/route").then((response) => {
      response.data.data.routeList = response.data.data.routeList.map((route, index) => {
        route.id = index;
        return route;
      });
      if (response?.data?.data?.routeList?.length > 0) {
        setImportData(response.data.data.routeList);
      } else {
        setImportData(null);
      }
    });
  }, []);

  return (
    <div className="flex flex-col h-full">
      <AnimatePresence>
        {importExcelData && isExcelModalOpen && (
          <Modal title={"엑셀 파일 확인"} acceptLabel="등록" handleClose={() => setIsExcelModalOpen(false)} handleAccept={handlePostData}>
            <DataTable
              data={importExcelData}
              sort={["index", "address", "jobDate", "x", "y", "jobStartTime", "jobEndTime", "jobDuration", "itemQty", "itemQtyUnit", "requireVehicleType", "memo"]}
              headers={{
                index: "no",
                address: "주소",
                jobDate: "작업일",
                x: "경도",
                y: "위도",
                jobStartTime: "시작시간",
                jobEndTime: "종료시간",
                jobDuration: "작업시간",
                itemQty: "적재량",
                itemQtyUnit: "단위",
                requireVehicleType: "차종",
                memo: "메모",
              }}
              indexMode={true}
              className="table table-auto"
            />
          </Modal>
        )}
        {importData && isImportModalOpen && (
          <Modal title={"등록한 경유지 확인"} closeLabel="닫기" handleClose={() => setIsImportModalOpen(false)}>
            <DataTable
              data={importData}
              sort={["index", "address", "jobDate", "x", "y", "jobStartTime", "jobEndTime", "jobDuration", "itemQty", "itemQtyUnit", "requireVehicleType", "memo"]}
              headers={{
                index: "순서",
                address: "주소",
                jobDate: "작업일",
                x: "경도",
                y: "위도",
                jobStartTime: "시작시간",
                jobEndTime: "종료시간",
                jobDuration: "작업시간",
                itemQty: "적재량",
                itemQtyUnit: "단위",
                requireVehicleType: "차종",
                memo: "메모",
              }}
              indexMode={true}
              className="table table-auto"
            />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>{isLoading && <Loading title={"데이터를 불러오는중입니다."} content={"잠시만 기다려주세요"} />}</AnimatePresence>
      <div className="relative "></div>
      <main className="flex flex-1">
        <div className="w-[350px] bg-default-50 px-4 py-6 pt-2 flex flex-col border-r border-default-200">
          <ScrollShadow hideScrollBar="true" className="relative flex-1">
            <div className="absolute w-full">
              <Accordion
                itemClasses={{
                  content: "pb-2 pt-0 px-0",
                }}
                className="p-0"
                selectionMode="multiple"
                keepContentMounted
                defaultExpandedKeys={["1", "2", "3", "4"]}
              >
                <AccordionItem open title="데이터업로드" key="1">
                  <Dropzone onDataExport={handleSetExcelData} setIsLoading={setIsLoading} />
                  <Button fullWidth onPress={downloadFile} size="sm" color="default" variant="flat">
                    샘플데이터 다운로드
                  </Button>
                </AccordionItem>
                {resultdata && (
                  <AccordionItem title="경로최적화 결과" key="2">
                    <DriverCardList data={resultdata} onSelectedIdChange={onSelectedIdChange} />
                  </AccordionItem>
                )}
                {importData && !resultdata && (
                  <AccordionItem title="경유지 상세정보" key="3">
                    <VisitCardList data={importData} handleClick={handleFlyToCoordinates} />
                  </AccordionItem>
                )}
                {!importData && !resultdata && (
                  <AccordionItem title="경유지 상세정보" key="4">
                    <div className="flex items-center gap-1 mb-2">
                      <MapPin weight="fill" className="text-default-600" />
                      <span className="mr-2 font-medium">경유지 상세정보</span>
                      <span className="text-sm">전체데이터 0건</span>
                    </div>
                    <div className="flex flex-col items-center justify-center p-5 text-center border rounded-md border-default-200 bg-background">
                      <div className="mb-2 text-sm text-default-400">경유지 정보가 없습니다👀</div>
                      <div className="text-xs text-default-400">
                        데이터 업로드에서
                        <br />
                        경유지 정보를 업로드 해보세요.
                      </div>
                    </div>
                  </AccordionItem>
                )}
              </Accordion>
            </div>
          </ScrollShadow>

          <div className="mb-4"></div>
          {!resultdata ? (
            <div onClick={handleOptimize} className="flex items-center justify-center px-2 py-2 mt-auto text-white rounded-md bg-primary-500">
              <i className="mr-1 text-xl ph ph-line-segments"></i>
              <span className="">경로 최적화</span>
              {importData ? <span className="ml-2 text-xs text-white">방문지 {importData.length}곳</span> : null}
            </div>
          ) : (
            <div onClick={() => handleObjectToExcel()} className="flex items-center justify-center px-2 py-2 mt-auto text-white rounded-md bg-primary-500">
              <i className="mr-1 text-xl ph ph-line-segments"></i>
              <span className="">엑셀 다운로드</span>
            </div>
          )}
        </div>
        <div className="relative flex-1 overflow-hidden">
          {resultdata && <RoutePanel data={resultdata} selectedId={selectedId} handleFlyToCoordinates={handleFlyToCoordinates} />}
          <VusMap ref={mapRef} />
        </div>
      </main>
    </div>
  );
}

export default Vrp;
