export const R = 6371; // 지구의 반경(km)

const deg2rad = (deg) => deg * (Math.PI / 180);
const rad2deg = (rad) => rad * (180 / Math.PI);

export const getDistance = (lat1, lon1, lat2, lon2) => {
  let dLat = deg2rad(lat2 - lat1);
  let dLon = deg2rad(lon2 - lon1);
  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

export const adjustCoordinates = (markers, radius) => {
  console.time("Execution Time");

  const calculateNewPosition = (lat, lon, brng, dist) => {
    dist = dist / R; // convert dist to angular distance in radians
    brng = deg2rad(brng);

    let lat1 = deg2rad(lat);
    let lon1 = deg2rad(lon);

    let lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) + Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));
    let lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(dist) * Math.cos(lat1), Math.cos(dist) - Math.sin(lat1) * Math.sin(lat2));
    return {
      lat: rad2deg(lat2),
      lon: rad2deg(lon2),
    };
  };

  // 마커를 분산시키는 로직
  let adjustedMarkers = [];
  let adjusted = new Set();

  markers.forEach((marker, i) => {
    if (adjusted.has(i)) return;

    let overlaps = markers.reduce((acc, other, j) => {
      if (i !== j && !adjusted.has(j)) {
        let distance = getDistance(marker.y, marker.x, other.y, other.x);
        if (distance < radius / 1000) {
          acc.push({ marker: other, index: j });
        }
      }
      return acc;
    }, []);

    if (overlaps.length > 0) {
      let angleIncrement = 360 / (overlaps.length + 1);
      overlaps.forEach((overlap, index) => {
        let newCoords = calculateNewPosition(marker.y, marker.x, angleIncrement * (index + 1), radius / 1000);
        adjustedMarkers.push({ ...overlap.marker, x: newCoords.lon, y: newCoords.lat });
        adjusted.add(overlap.index);
      });
      let newCoords = calculateNewPosition(marker.y, marker.x, 0, radius / 1000); // Adjust the original marker as well
      adjustedMarkers.push({ ...marker, x: newCoords.lon, y: newCoords.lat });
      adjusted.add(i);
    } else {
      adjustedMarkers.push(marker);
    }
  });
  console.timeEnd("Execution Time");
  return adjustedMarkers;
};
