import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./index.css";

import { ToastProvider } from "./utils/ToastContext";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
