import axios from "axios";
import { EventEmitter } from "events";

const axiosInstance = axios.create();
export const authEvents = new EventEmitter();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = token ? `Bearer ${token}` : "";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    // Check if the error is 401 and not already retried
    if (originalRequest.url === "/api/v1/jwt/token/refresh") {
      authEvents.emit("logout"); // Emit logout event upon failure
      return Promise.reject(error);
    }
    if (error.response && (error.response.status === 401 || error.response.data.code === "EXPIRE") && !originalRequest._retry) {
      originalRequest._retry = true; // Set the retry flag
      const refreshToken = localStorage.getItem("refreshToken");

      return axiosInstance
        .post("/api/v1/jwt/token/refresh", {}, { params: { refreshToken } })
        .then((res) => {
          if (res.data.code === "SUCC") {
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("refreshToken", res.data.data.refreshToken);
            originalRequest.headers["Authorization"] = `Bearer ${res.data.data.token}`;
            return axios(originalRequest); // Retry the original request
          }
          throw new Error("Failed to refresh token"); // Throw an error to skip retrying
        })
        .catch((err) => {
          return Promise.reject(err); // Reject the promise to stop processing
        });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
