import React from "react";
import { Note, Package, HourglassMedium, Truck } from "@phosphor-icons/react";
import Card from "./Card";
import { motion } from "framer-motion";
import { getQtyUnitName, getVehicleTypeName } from "../utils/FormatUtils";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1, // 각 자식 컴포넌트 사이의 지연 시간 (초 단위)
    },
  },
};

const itemVariants = {
  hidden: { opacity: -2, x: 20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.2 } },
};

function VisitCardList({ data, handleClick }) {
  return (
    <motion.div className="flex flex-col flex-wrap w-full py-2 space-y-2 overflow-y-auto" variants={containerVariants} initial="hidden" animate="visible" key={data.length}>
      {data?.map((route, index) => (
        <VisitCard key={route.id} index={index} route={route} handleClick={handleClick} />
      ))}
    </motion.div>
  );
}

function VisitCard({ route, index, handleClick }) {
  return (
    <motion.div variants={itemVariants}>
      <Card hoverEffect={true} handleClick={() => handleClick(route.x, route.y, route)} className="flex-1 p-3 bg-white border rounded-md border-default-200">
        <div className="flex items-start mb-2 text-sm">
          <div className="flex items-center justify-center w-5 h-5 p-1 mr-1 border rounded-md border-default-200 text-default-400 bg-default-100">{index + 1}</div>
          <span className="">{route.address}</span>
        </div>
        <div className="flex flex-wrap text-xs text-default-400 gap-x-4 gap-y-1">
          <div className="flex items-center">
            <Truck className="mr-1" />
            <div>{getVehicleTypeName(route.requireVehicleType)}</div>
          </div>
          <div className="flex items-center">
            <Package className="mr-1" />
            <span>
              {route.itemQty}
              {getQtyUnitName(route.itemQtyUnit)}
            </span>
          </div>
          <div className="flex items-center">
            <HourglassMedium className="mr-1" />
            <span>{route.jobDuration}분 작업</span>
          </div>
          <div className="flex items-center">
            <Note className="mr-1" />
            <span>{route.memo}</span>
          </div>
        </div>
      </Card>
    </motion.div>
  );
}

export default VisitCardList;
