import React from "react";
import Router from "./router/Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastProvider } from "./utils/ToastContext";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

const queryClient = new QueryClient();
function App() {
  return (
    <NextUIProvider
      locale="ko-KR"
      className="flex flex-col h-screen max-h-screen text-foreground bg-background"
    >
      <NextThemesProvider attribute="class" defaultTheme="dark">
        <QueryClientProvider client={queryClient}>
          <CookiesProvider>
            <Router />
          </CookiesProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </NextThemesProvider>
    </NextUIProvider>
  );
}

export default App;
