import React from "react";
import axiosInstance from "../../utils/PrivateApi";

import { Card, CardBody, Image, CardFooter } from "@nextui-org/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { OPTIONS } from "../../constants/options";

function SampleDataContent({ onClose, setIsSideOpen, setRouteOption }) {
  const queryClient = useQueryClient();
  const { data: sampleSenario } = useQuery({
    queryKey: ["sampleSenario"],
    queryFn: () => axiosInstance.get("/api/v1/scenario/sample"),
    select: (data) => data.data.data.scenarioList,
  });

  const sampleDataMutation = useMutation({
    mutationFn: (data) => {
      return axiosInstance.post("/api/v1/scenario/load", data);
    },
    onSuccess: (result) => {
      if (result.data.code === "SUCC") {
        console.log("데이터 세팅완료");
        const queryKeys = [["route"], ["driver"]];
        queryKeys.forEach((key) =>
          queryClient.invalidateQueries({ queryKey: key })
        );

        setIsSideOpen();
        onClose();
      } else {
        alert("처리에 실패했습니다.");
      }
    },
    onError: (error) => {
      alert("처리에 실패했습니다.");
      console.error("Error adding user", error);
    },
  });

  const senarioImg = {
    medic: `${process.env.PUBLIC_URL}/medical.png`,
    normal: `${process.env.PUBLIC_URL}/normal.png`,
  };

  return (
    <div className="flex w-full gap-3">
      {sampleSenario &&
        sampleSenario.map((item, index) => (
          <div className="basis-1/3 w-9/10" key={index}>
            <Card
              shadow="sm"
              key={index}
              isPressable
              fullWidth
              onPress={() => {
                sampleDataMutation.mutate({ scenarioId: item.id });
                setRouteOption(item.routeOption);
              }}
            >
              <CardBody className="p-0 overflow-visible">
                <Image
                  shadow="sm"
                  radius="lg"
                  width="100%"
                  alt={item.name}
                  className="w-full object-cover h-[200px]"
                  src={senarioImg[item.image]}
                />
              </CardBody>
              <CardFooter className="flex flex-col text-small ">
                <p className="font-bold text-default-800">{item.name}</p>
                <p className="text-default-500">
                  방문지 {item.nodeCnt} • 차량 {item.driverCnt} • 옵션{" "}
                  {OPTIONS[item.routeOption]}
                </p>
              </CardFooter>
            </Card>
          </div>
        ))}
    </div>
  );
}

export default SampleDataContent;
