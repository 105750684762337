import React from "react";
import { Clock, MapPin, Plus, RoadHorizon, Table } from "@phosphor-icons/react";
import { Accordion, AccordionItem, ScrollShadow, Button } from "@nextui-org/react";

const OptionsContent = ({ defaultExpandedKeys, onAccordionChange }) => {
  return (
    <div className="flex flex-col p-4 space-y-4 border-b grow bg-default-50 border-default-200">
      <div className="flex items-center justify-between">
        <span className="font-semibold text-default-800">상세 옵션 선택</span>
      </div>
      <ScrollShadow hideScrollBar className="relative flex-1">
        <div className="absolute w-full">
          <Accordion
            selectionMode="single"
            defaultExpandedKeys={[defaultExpandedKeys]}
            disallowEmptySelection={true}
            hideIndicator={true}
            onSelectionChange={({ currentKey }) => onAccordionChange(currentKey)}
            className="p-0 space-y-3"
            showDivider={false}
            itemClasses={{
              base: "p-0 w-full rounded-xl bg-default-50 shadow-sm border border-default-300 data-[open=true]:border-primary-400 data-[open=true]:dark:border-primary-600",
              title: "font-normal text-medium text-default-500 data-[open=true]:text-primary-400 data-[open=true]:dark:text-primary-600",
              heading: "font-normal text-medium text-default-300 data-[open=true]:text-primary-400 data-[open=true]:dark:text-primary-600 text-xl",
              content: "p-3 pt-0 text-sm text-default-600",
              trigger: "p-3",
            }}
          >
            <AccordionItem key={"NONE"} title="기본" startContent={<RoadHorizon weight="duotone" />}>
              특정한 기준이나 조건을 설정하지 않고, 시스템이 자동으로 가장 적절한 배차를 진행합니다.
            </AccordionItem>
            <AccordionItem key={"DISTANCE"} title="운행거리균등" startContent={<RoadHorizon weight="duotone" />}>
              운행 거리를 균등하게 배분합니다.
            </AccordionItem>
            <AccordionItem key={"NODE"} title="방문지균등" startContent={<MapPin weight="duotone" />}>
              기사가 방문할 곳을 균등하게 배분합니다.
            </AccordionItem>
            <AccordionItem key={"TIME"} title="작업시간균등" startContent={<Clock weight="duotone" />}>
              기사의 작업시간을 우선으로 균등하게 배분합니다.
            </AccordionItem>
          </Accordion>
        </div>
      </ScrollShadow>
    </div>
  );
};

export default OptionsContent;
