import React from "react";
import { motion } from "framer-motion";

const Modal = ({ children, title, handleClose, handleAccept, acceptLabel = "확인", closeLabel = "취소" }) => {
  return (
    <motion.div
      className="backdrop"
      initial={{
        opacity: 0,
        scale: 1.1,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.1 }}>
      <div className="flex flex-col items-start py-10 px-8 bg-white rounded-md max-w-[70vw] min-w-96 max-h-[80vh] min-h-96 gap-6">
        <div className="text-xl text-slate-900 font-semibold">{title}</div>
        <div className="overflow-scroll w-full flex-1 relative scrollbar-hide">{children}</div>
        <div className="flex ml-auto gap-2">
          <button onClick={handleClose} className="px-4 py-2 bg-slate-200 text-slate-400 rounded-md">
            {closeLabel}
          </button>
          {handleAccept && (
            <button onClick={handleAccept} className="px-4 py-2 bg-indigo-500 text-white rounded-md">
              {acceptLabel}
            </button>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Modal;
