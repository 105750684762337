import React from "react";

function DataTable({ data, sort, headers, indexMode = false, className = "" }) {
  const tableHeaders = sort || Object.keys(data[0]);

  if (indexMode) {
    data = data.map((row, index) => {
      return { ...row, index: index + 1 };
    });
  }

  return (
    <table className={className}>
      <thead className="sticky top-0">
        <tr>
          {tableHeaders.map((key) => (
            <th key={key}>
              {/* headers prop에 매핑된 헤더 이름이 있으면 사용하고, 없으면 키 값을 사용합니다. */}
              {headers && headers[key] ? headers[key] : key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {tableHeaders.map((key) => (
              <td key={key}>{row[key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DataTable;
