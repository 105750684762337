import React from "react";
import { Button, Card, CardBody } from "@nextui-org/react";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileImage from "../assets/images/rafiki.png";
import axiosInstance from "../utils/PrivateApi";
import * as XLSX from "xlsx";
import { FileArrowDown, Circuitry, CaretRight } from "@phosphor-icons/react";
import { getQtyUnitCode, getQtyUnitName, getVehicleTypeCode, getVehicleTypeName } from "../utils/FormatUtils";

export const FileInput = ({ updateRoute, onClose, setImportRouteExcelData, onSampleConfirmOpen }) => {
  function downloadFile() {
    // 파일 URL 생성
    const fileUrl = `${process.env.PUBLIC_URL}/route_sample.xlsx`;

    // `<a>` 태그를 동적으로 생성하고 클릭 이벤트를 트리거하여 파일 다운로드
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "route_sample.xlsx"); // 다운로드될 파일 이름 설정
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const onDrop = useCallback((file) => {
    if (!file[0]) return;
    file = file[0];
    if (!file.name.toLowerCase().match(/\.(xls|xlsx|csv)$/)) {
      alert("엑셀 파일만 업로드 가능합니다.");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = reader.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        range: 1,
        header: ["address", "jobDate", "y", "x", "jobStartTime", "jobEndTime", "jobDuration", "itemQty", "itemQtyUnit", "requireVehicleType", "memo"],
      });

      jsonData.forEach((row) => {
        if (!row.memo) row.memo = "-";
        row.requireVehicleType = getVehicleTypeCode(row.requireVehicleType);
        row.itemQtyUnit = getQtyUnitCode(row.itemQtyUnit?.toLowerCase());
      });

      updateRoute.mutate(jsonData);
    };

    reader.readAsBinaryString(file);
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <div>
      <div className={" flex flex-col flex-1 items-center justify-center p-4 rounded-xl space-y-4" + (isDragActive ? " bg-primary bg-opacity-30" : "")} {...getRootProps()}>
        <input {...getInputProps()} />

        <div className="text-xl text-default-700">엑셀 파일을 업로드해주세요.</div>

        <img src={FileImage} className="w-96" alt="" />
        <Button onPress={open} color="default" size="sm" children={"찾아보기"}></Button>
        <div className="text-xl text-default-700">또는 파일을 여기로 드래그 하세요.</div>
      </div>
    </div>
  );
};
