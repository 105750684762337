import React, { createContext, useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, cssTransition } from "react-toastify";
const custom = cssTransition({
    enter: "toastIn",
    exit: "toastOut",
    collapse: true,
    collapseDuration: 300,
});
const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const showToast = (message, options) => {
        toast(message, options);
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <ToastContainer position={"top-center"} autoClose={3000} hideProgressBar={false} theme={"dark"} transition={custom} limit={999} closeButton={false} />
        </ToastContext.Provider>
    );
};
