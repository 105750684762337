import React, { createContext, useEffect, useState } from "react";
import { authEvents } from "./PrivateApi"; // Import the event emitter
import { useRoutes } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token") && !!localStorage.getItem("refreshToken"));
  const [userId, setIsUserId] = useState(localStorage.getItem("userId"));
  useEffect(() => {
    const handleLogout = () => {
      console.log("로그아웃");
      logout();
    }; // Define a handler that calls logout
    authEvents.on("logout", handleLogout); // Listen to logout event

    return () => {
      authEvents.off("logout", handleLogout); // Clean up the event listener
    };
  }, []);

  const login = (userId = null) => {
    setIsLoggedIn(true);
    setIsUserId(userId);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    setIsUserId(null);
    setIsLoggedIn(false);
  };

  return <AuthContext.Provider value={{ isLoggedIn, login, logout, userId }}>{children}</AuthContext.Provider>;
};
