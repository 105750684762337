import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import Vrp from "../pages/Vrp";
import OptimalDispatch from "../pages/OptimalDispatch";
import { AuthProvider } from "../utils/AuthContext";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import { Header } from "../components";
export default function Router() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/vrp"
            element={
              <ProtectedRoute>
                <Header />
                <Vrp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/OptimalDispatch"
            element={
              <ProtectedRoute>
                <Header />
                <OptimalDispatch />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
