//분을 0시간 0분 으로 변환, 미터를 킬로미터로 변환
export const convertDuration = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  // 시간, 분, 초를 두 자리 숫자로 포매팅
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  let result = "";
  if (hours !== "00") {
    result += hours + "시간 ";
  }
  result += minutes + "분";
  return result;
};

export const convertTime = (time) => {
  time = time.toString();
  let hours = time.substring(0, 2);
  let minutes = time.substring(2, 4);
  return `${hours}:${minutes}`;
};

//HHmm to HH:mm
export const convertTimeToHHmm = (time) => {
  time = time.toString();
  let hours = time.substring(0, 2);
  let minutes = time.substring(2, 4);
  return `${hours}:${minutes}`;
};

export const convertDistance = (distance) => {
  return `${(distance / 1000).toFixed(2)}km`;
};

export const convertDate = function (source, dateSeparator) {
  if (source == null) {
    return null;
  }
  if (dateSeparator == null) {
    dateSeparator = "";
  }
  if (typeof source == "string") {
    source = source.trim();
    if (/^[0-9]{8}.*/.test(source)) {
      return source.replace(
        /^(....)(..)(..).*/g,
        "$1" + dateSeparator + "$2" + dateSeparator + "$3"
      );
    } else if (/^(....).?(..).?(..).*/.test(source)) {
      return source.replace(
        /^(....).?(..).?(..).*/,
        "$1" + dateSeparator + "$2" + dateSeparator + "$3"
      );
    } else {
      return source;
    }
  } else if (source.getFullYear) {
    var yyyy = source.getFullYear();
    var mm =
      source.getMonth() < 9
        ? "0" + (source.getMonth() + 1)
        : source.getMonth() + 1; // getMonth() is zero-based
    var dd = source.getDate() < 10 ? "0" + source.getDate() : source.getDate();
    return ""
      .concat(yyyy)
      .concat(dateSeparator)
      .concat(mm)
      .concat(dateSeparator)
      .concat(dd);
  } else {
    return source;
  }
};

export const stringToPrettyColor = (type, str) => {
  if (str === undefined) str = "";
  let sum = str.length * 32904;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i) * 100;
  }
  var hue = 60 + (sum % 270);
  var saturation = 60 + (sum % 29);
  var lightness = 30 + (sum % 10);

  if (type === "hsl") {
    return "hsl(" + hue + "," + saturation + "%," + lightness + "%)";
  }
  if (type === "hex") {
    lightness += 27;
    lightness /= 100;
    const a = (saturation * Math.min(lightness, 1 - lightness)) / 100;
    const f = (n) => {
      const k = (n + hue / 30) % 12;
      const color = lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0");
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
  if (type === "rgb") {
    lightness += 27;
    lightness /= 100;
    const a = (saturation * Math.min(lightness, 1 - lightness)) / 100;
    const f = (n) => {
      const k = (n + hue / 30) % 12;
      const color = lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color);
    };
    return `rgb(${f(0)},${f(8)},${f(4)})`;
  }
  if (type === "rgbArray") {
    lightness += 27;
    lightness /= 100;
    const a = (saturation * Math.min(lightness, 1 - lightness)) / 100;
    const f = (n) => {
      const k = (n + hue / 30) % 12;
      const color = lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color);
    };
    return [f(0), f(8), f(4)];
  }

  return;
};
export const decomposeHangul = (text) => {
  const initialSounds = "ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎ";
  const medials = "ㅏㅐㅑㅒㅓㅔㅕㅖㅗㅘㅙㅚㅛㅜㅝㅞㅟㅠㅡㅢㅣ";
  const finals = "ㄱㄲㄳㄴㄵㄶㄷㄹㄺㄻㄼㄽㄾㄿㅀㅁㅂㅄㅅㅆㅇㅈㅊㅋㅌㅍㅎ";

  let result = "";
  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i);
    if (code >= 0xac00 && code <= 0xd7a3) {
      const uni = code - 0xac00;
      const cho = Math.floor(uni / (21 * 28));
      const jung = Math.floor((uni % (21 * 28)) / 28);
      const jong = uni % 28;

      result += initialSounds[cho];
      result += medials[jung];
      if (jong !== 0) result += finals[jong - 1];
    } else {
      result += text[i];
    }
  }
  return result;
};

const vehicleTypeMapping = {
  "001": "탑차",
  "002": "냉장/냉동",
};

const qtyUnitMapping = {
  "001": "kg",
  "002": "g",
};

export const getVehicleTypeCode = (name) => {
  const code = Object.keys(vehicleTypeMapping).find(
    (key) => vehicleTypeMapping[key] === name
  );
  return code || "999";
};
export const getVehicleTypeName = (code) => {
  return vehicleTypeMapping[code] || "기타";
};

export const getQtyUnitCode = (name) => {
  const code = Object.keys(qtyUnitMapping).find(
    (key) => qtyUnitMapping[key] === name
  );
  return code || "999";
};

export const getQtyUnitName = (code) => {
  return qtyUnitMapping[code] || "?";
};

export const getCommaNum = (v) => {
  if (v == null || v === "") {
    return "0";
  }
  v = parseInt(v, 10).toString();
  var regexp = /\B(?=(\d{3})+(?!\d))/g;
  return v.toString().replace(regexp, ",");
};
